import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangePreferredUsernameError } from '../../../domain/errors/ChangePreferredUsernameError';
import { PreferredUsernameIncludedForbiddenWordsError } from '../../../domain/errors/PreferredUsernameIncludedForbiddenWordsError';
import { PreferredUsernameValidationError } from '../../../domain/errors/PreferredUsernameValidationError';

import type { ChangePreferredUsername } from '../../../domain/user';
import type { ResponseErrorBody } from '../../../pages/api/users/profiles/preferred-usernames';

export const changePreferredUsername: ChangePreferredUsername = async ({
  preferredUsername,
}) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ preferredUsername }),
  };

  const response = await fetch(apiPath.changePreferredUsername, options);

  if (response.status !== httpStatusCode.ok) {
    if (response.status === httpStatusCode.unprocessableEntity) {
      const responseBody = (await response.json()) as ResponseErrorBody;
      if (
        responseBody.message === 'PreferredUsername includes forbiddenWords'
      ) {
        throw new PreferredUsernameIncludedForbiddenWordsError();
      }

      throw new PreferredUsernameValidationError();
    }

    throw new ChangePreferredUsernameError();
  }
};
