import {
  createFailureResult,
  createSuccessResult,
  httpStatusCode,
  PrefectureId,
} from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeAddressError } from '../../../domain/errors/ChangeAddressError';
import { FetchAddressByPostalCodeError } from '../../../domain/errors/FetchAddressByPostalCodeError';

import type { PostalCode, ChangeAddress } from '../../../domain/address';
import type { FetchAddressByPostalCode } from '@nurse-senka/nurse-senka-web-ui';

type FetchAddressByPostalCodeResponse = {
  postalCode: PostalCode;
  prefectureId: PrefectureId;
  locality: string;
};

export const fetchAddressByPostalCode: FetchAddressByPostalCode = async (
  postalCode,
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ postalCode }),
  };

  const response = await fetch(apiPath.fetchAddressByPostalCode, options);
  if (response.status !== httpStatusCode.ok) {
    return createFailureResult(new FetchAddressByPostalCodeError());
  }

  const responseBody =
    (await response.json()) as FetchAddressByPostalCodeResponse;

  return createSuccessResult(responseBody);
};

export const changeAddress: ChangeAddress = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeAddress, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeAddressError();
  }
};
