/* eslint no-magic-numbers: 0, id-length: 0, no-plusplus: 0 */

const isSurrogatePear = (upper: number, lower: number): boolean =>
  upper >= 0xd800 && upper <= 0xdbff && lower >= 0xdc00 && lower <= 0xdfff;

export const mbStrLen = (str: string): number => {
  let ret = 0;

  for (let i = 0; i < str.length; i++, ret++) {
    const upper = str.charCodeAt(i);
    const lower = str.length > i + 1 ? str.charCodeAt(i + 1) : 0;

    if (isSurrogatePear(upper, lower)) {
      i++;
    }
  }

  return ret;
};

export const mbSubStr = (str: string, begin: number, end: number): string => {
  let ret = '';
  for (let i = 0, len = 0; i < str.length; i++, len++) {
    const upper = str.charCodeAt(i);
    const lower = str.length > i + 1 ? str.charCodeAt(i + 1) : 0;
    let s = '';

    if (isSurrogatePear(upper, lower)) {
      i++;
      s = String.fromCharCode(upper, lower);
    } else {
      s = String.fromCharCode(upper);
    }

    if (begin <= len && len < end) {
      ret += s;
    }
  }

  return ret;
};
