import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeCustomLicenseError } from '../../../domain/errors/ChangeCustomLicenseError';

import type { ChangeCustomLicense } from '../../../domain/customLicense';

export const changeCustomLicense: ChangeCustomLicense = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeCustomLicense, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeCustomLicenseError(
      `${response.statusText} src/api/client/fetch/customLicense.ts failed to changeCustomLicense`,
    );
  }
};
