import { proxy } from 'valtio';

import {
  createDayList,
  defaultBirthYear,
  initBirthday,
  initBirthMonth,
} from '../../domain/birthdate';
import { PrefectureId, prefectureTokyo } from '../../domain/prefecture';
import { QualificationCode } from '../../domain/qualification';
import {
  createGraduationYearList,
  defaultSchoolTypeId,
  GraduationYear,
  SchoolTypeId,
} from '../../domain/school';
import { Email } from '../../domain/user';

import type { BirthMonth } from '@nurse-senka/nurse-senka-frontend-sdk';

export type RegistrationFormState = {
  birthYear: number;
  birthMonth: BirthMonth;
  birthday: number;
  birthdayList: number[];
  prefectureId: PrefectureId;
  givenName: string;
  familyName: string;
  isReceiveHospitalNotices: boolean;
  qualificationCodeList?: QualificationCode[];
  email?: Email;
  password?: string;
  graduationYear?: GraduationYear;
  schoolName?: string;
  schoolTypeId?: SchoolTypeId;
  phoneNumber?: string;
};

const registrationFormState = proxy<RegistrationFormState>({
  birthYear: defaultBirthYear,
  birthMonth: initBirthMonth,
  birthday: initBirthday,
  birthdayList: createDayList(),
  prefectureId: prefectureTokyo.id,
  givenName: '',
  familyName: '',
  isReceiveHospitalNotices: true,
  // eslint-disable-next-line no-magic-numbers
  graduationYear: createGraduationYearList()[0],
  schoolTypeId: defaultSchoolTypeId.id,
});

export const updateQualificationCodeList = (
  qualificationCode: QualificationCode,
): void => {
  if (!registrationFormState.qualificationCodeList) {
    registrationFormState.qualificationCodeList = [];
  }

  if (registrationFormState.qualificationCodeList.includes(qualificationCode)) {
    registrationFormState.qualificationCodeList =
      registrationFormState.qualificationCodeList.filter(
        (value) => value !== qualificationCode,
      );

    return;
  }

  registrationFormState.qualificationCodeList =
    registrationFormState.qualificationCodeList.concat([qualificationCode]);
};

export const updateBirthYear = (birthYear: number): void => {
  registrationFormState.birthYear = birthYear;
};

export const updateBirthMonth = (birthMonth: BirthMonth): void => {
  registrationFormState.birthMonth = birthMonth;
};

export const updateBirthday = (birthday: number): void => {
  registrationFormState.birthday = birthday;
};

export const updateBirthdayList = (birthdayList: number[]): void => {
  registrationFormState.birthdayList = birthdayList;
};

export const updatePrefectureId = (prefectureId: PrefectureId): void => {
  registrationFormState.prefectureId = prefectureId;
};

export const updateGivenName = (givenName: string): void => {
  registrationFormState.givenName = givenName;
};

export const updateFamilyName = (familyName: string): void => {
  registrationFormState.familyName = familyName;
};

export const updatePhoneNumber = (phoneNumber: string): void => {
  registrationFormState.phoneNumber = phoneNumber;
};

export const updateEmail = (email: Email): void => {
  registrationFormState.email = email;
};

export const updatePassword = (password: string): void => {
  registrationFormState.password = password;
};

export const updateGraduationYear = (graduationYear: GraduationYear): void => {
  registrationFormState.graduationYear = graduationYear;
};

export const updateSchoolName = (schoolName: string): void => {
  registrationFormState.schoolName = schoolName;
};

export const updateSchoolTypeId = (schoolTypeId: SchoolTypeId): void => {
  registrationFormState.schoolTypeId = schoolTypeId;
};

export const updateIsReceiveHospitalNotices = (
  isReceiveHospitalNotices: boolean,
): void => {
  registrationFormState.isReceiveHospitalNotices = isReceiveHospitalNotices;
};

export const registrationFormStateSelector = (): RegistrationFormState =>
  registrationFormState;
