import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../constants/url';
import { ChangeGenderError } from '../../domain/errors/ChangeGenderError';
import { ChangeGender } from '../../domain/gender';

export const changeGender: ChangeGender = async (request) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeGender, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeGenderError();
  }
};
