import { GoogleTagManager } from '@nurse-senka/nurse-senka-web-ui';

import { GoogleAdManager } from '../components/GoogleAdManager';
import { googleTagManagerId } from '../constants/env';
import {
  useGoogleAdManagerDefineSlots,
  useGoogleAdManagerRemoveSlots,
} from '../hooks';

import type { AppProps } from 'next/app';
import 'ress/ress.css';
import '@nurse-senka/nurse-senka-web-ui/style.css';

const CustomApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  useGoogleAdManagerDefineSlots();
  useGoogleAdManagerRemoveSlots();

  return (
    <>
      <GoogleTagManager googleTagManagerId={googleTagManagerId()} />
      <GoogleAdManager />
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Component {...pageProps} />
    </>
  );
};

export default CustomApp;
