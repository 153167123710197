import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeAffiliationError } from '../../../domain/errors/ChangeAffiliationError';

import type { ChangeAffiliation } from '../../../domain/affiliation';

export const changeAffiliation: ChangeAffiliation = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeAffiliation, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeAffiliationError(
      `${response.statusText} src/api/client/fetch/affiliation.ts failed to changeAffiliation`,
    );
  }
};
