import {
  validation,
  isScholarshipStatusId,
  isScholarshipTypeId,
  isBorrowingPeriodId,
  type BorrowingPeriodId,
  type ScholarshipStatusId,
  type ScholarshipTypeId,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

export type ChangeScholarshipRequest = {
  scholarshipStatus: ScholarshipStatusId;
  scholarshipType: ScholarshipTypeId;
  borrowingPeriod: BorrowingPeriodId;
};

export const changeScholarshipRequestSchema = z.object({
  scholarshipStatus: z.number().refine((value) => isScholarshipStatusId(value)),
  scholarshipType: z.number().refine((value) => isScholarshipTypeId(value)),
  borrowingPeriod: z.number().refine((value) => isBorrowingPeriodId(value)),
});

export const isChangeScholarshipRequest = (
  value: unknown,
): value is ChangeScholarshipRequest =>
  validation(changeScholarshipRequestSchema, value).isValidate;

export type ChangeScholarship = (
  request: ChangeScholarshipRequest,
) => Promise<void>;
