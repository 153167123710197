import {
  AccountTemplate,
  ProfileSettingContents,
  InitQualificationsAndWork,
  InitBasicInfo,
  InitAcademicBackground,
} from '@nurse-senka/nurse-senka-web-ui';

import { fetchAddressByPostalCode } from '../../api/client/fetch/address';
import { AppHeader } from '../../components/AppHeader';
import { appUrl, portalUrl } from '../../constants/url';
import { activityAreaList } from '../../domain/activityArea';
import { affiliations } from '../../domain/affiliation';
import { employments } from '../../domain/employment';
import { licenses } from '../../domain/license';
import { qualifications } from '../../domain/qualification';
import {
  createUpdatedGraduationYearList,
  schoolTypes,
} from '../../domain/school';

import { updaters } from './updaters';
import { validators } from './validators';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'Myページ',
    url: appUrl.top,
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'プロフィール変更',
    url: appUrl.changeProfile,
  } as const,
];

const qualificationAttributes = qualifications.map((qualification) => ({
  id: String(qualification.code),
  name: 'qualificationCode',
  value: qualification.code,
  label: qualification.label,
}));

const graduationYearAttributes = createUpdatedGraduationYearList().map(
  (value) => ({
    key: value,
    value: String(value),
    label: `${value}年`,
  }),
);

const namePlaceholders = {
  familyName: '看護',
  givenName: '花子',
} as const;

const kanaPlaceholders = {
  familyName: 'かんご',
  givenName: 'はなこ',
} as const;

const employmentAttributes = employments.map((employment) => ({
  key: employment.id,
  value: String(employment.id),
  label: employment.label,
}));

const affiliationAttributes = affiliations.map((affiliation) => ({
  id: `affiliationId${affiliation.id}`,
  name: 'affiliationId',
  value: affiliation.id,
  label: affiliation.label,
}));

const licenseAttributes = licenses.map((license) => ({
  id: `licenseId${license.id}`,
  name: 'licenseId',
  value: license.id,
  label: license.label,
}));

const schoolTypeAttributes = schoolTypes.map((schoolType) => ({
  key: schoolType.id,
  value: String(schoolType.id),
  label: schoolType.label,
}));

const activityAreaAttributes = activityAreaList.map((activityArea) => ({
  id: `activityAreaId${activityArea.id}`,
  name: 'activityArea',
  value: activityArea.id,
  label: activityArea.label,
}));

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  initBasicInfo?: InitBasicInfo;
  initQualificationsAndWork?: InitQualificationsAndWork;
  initAcademicBackground?: InitAcademicBackground;
};

export const ChangeProfileTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  initBasicInfo,
  initQualificationsAndWork,
  initAcademicBackground,
}) => (
  <>
    <AppHeader metaTag={metaTag} noIndex={false} />
    <AccountTemplate
      appEnv={appEnv}
      pageHeader={{ title: 'プロフィール変更' }}
      breadcrumbElements={breadcrumbElements}
      loggedInUser={loggedInUser}
      loading={false}
      isLoggedIn={true}
    >
      <ProfileSettingContents
        loggedInUser={loggedInUser}
        qualificationAttributes={qualificationAttributes}
        graduationYearAttributes={graduationYearAttributes}
        employmentAttributes={employmentAttributes}
        affiliationAttributes={affiliationAttributes}
        licenseIdAttributes={licenseAttributes}
        activityAreaAttributes={activityAreaAttributes}
        schoolTypeAttributes={schoolTypeAttributes}
        namePlaceholders={namePlaceholders}
        kanaPlaceholders={kanaPlaceholders}
        validators={validators}
        updaters={updaters}
        initBasicInfo={initBasicInfo}
        initQualificationsAndWork={initQualificationsAndWork}
        initAcademicBackground={initAcademicBackground}
        fetchAddressByPostalCode={fetchAddressByPostalCode}
      />
    </AccountTemplate>
  </>
);
