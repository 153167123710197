import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeActivityArea } from '../../../domain/errors/ChangeActivityArea';

import type { ChangeActivity } from '../../../domain/activityArea';

export const changeActivityArea: ChangeActivity = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeActivityArea, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeActivityArea(
      `${response.statusText} src/api/client/fetch/activityArea.ts failed to changeActivityArea`,
    );
  }
};
