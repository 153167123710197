import {
  AccountTemplate,
  EmailAlterationApplyContents,
  EmailAlterationSendMailContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { useState, useReducer, type FC, ChangeEvent, FormEvent } from 'react';

import { changeEmailApply } from '../../api/client/fetch/changeEmail';
import { AppHeader } from '../../components/AppHeader';
import { appUrl, portalUrl } from '../../constants/url';
import { isChangeEmailApplyOnClientRequest } from '../../domain/changeEmail';
import { isFailureResult } from '../../domain/result';
import {
  isEmail,
  formatEmail,
  type LoggedInUser,
  Email,
} from '../../domain/user';

import type { MetaTag } from '../../constants/metaTag';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'マイページ',
    url: appUrl.top,
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'メールアドレス変更',
    url: appUrl.emailChangeApply,
  } as const,
];

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
};

// eslint-disable-next-line max-lines-per-function
export const EmailChangeApplyTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
}) => {
  const [newEmail, setNewEmail] = useState<Email>();

  const [applied, updateApplied] = useReducer(() => true, false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const [errorMessage, setErrorMessage] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const changeNewEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;

    if (isEmail(inputEmail)) {
      setNewEmail(formatEmail(inputEmail));
      setDisableSubmitButton(false);
    }
  };

  // eslint-disable-next-line require-await
  const submit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    try {
      const request = { newEmail };
      if (isChangeEmailApplyOnClientRequest(request)) {
        const changeEmailApplyResult = await changeEmailApply(request);
        if (isFailureResult(changeEmailApplyResult)) {
          setErrorMessage(
            'メールアドレスは既に利用されています。お手数ですが別のメールアドレスをご利用ください。',
          );
          setDisableSubmitButton(false);
          setLoading(false);
        } else {
          setErrorMessage('');
          setDisableSubmitButton(true);
          setLoading(false);
          updateApplied();
        }
      } else {
        setErrorMessage('メールアドレスとして正しい値を入力して下さい。');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
      );
    }
  };

  const eventHandlers = {
    changeNewEmail,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        pageHeader={{ title: 'メールアドレス変更' }}
        appEnv={appEnv}
        breadcrumbElements={breadcrumbElements}
        loggedInUser={loggedInUser}
        loading={loading}
        isLoggedIn={true}
      >
        {applied && newEmail ? (
          <EmailAlterationSendMailContents
            appEnv={appEnv}
            newEmail={newEmail}
          />
        ) : (
          <EmailAlterationApplyContents
            appEnv={appEnv}
            loggedInUser={loggedInUser}
            disableSubmitButton={disableSubmitButton}
            currentEmail={loggedInUser.email ? loggedInUser.email : ''}
            eventHandlers={eventHandlers}
            errorMessage={errorMessage}
          />
        )}
      </AccountTemplate>
    </>
  );
};
