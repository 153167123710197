import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { removeSlots, defineSlots } from './ad';

export const useGoogleAdManagerRemoveSlots = () => {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeStart', removeSlots);
    router.events.on('routeChangeComplete', defineSlots);

    return () => {
      router.events.off('routeChangeStart', removeSlots);
      router.events.off('routeChangeComplete', defineSlots);
    };
  }, [router.events]);
};
