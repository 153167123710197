import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangePhoneNumberError } from '../../../domain/errors/ChangePhoneNumberError';

import type { ChangePhoneNumber } from '../../../domain/phoneNumber';

export const changePhoneNumber: ChangePhoneNumber = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changePhoneNumber, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangePhoneNumberError();
  }
};
