import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import RegistrationError from '../../../domain/errors/RegistrationError';
import UsedEmailAlreadyError from '../../../domain/errors/UsedEmailAlreadyError';
import {
  createFailureResult,
  createSuccessResult,
} from '../../../domain/result';

import type {
  ChangeEmailApplyOnClient,
  ChangeEmailApplyResponse,
} from '../../../domain/changeEmail';

export const changeEmailApply: ChangeEmailApplyOnClient = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.emailChangeApply, options);

  if (response.status === httpStatusCode.conflict) {
    return createFailureResult<UsedEmailAlreadyError>(
      new UsedEmailAlreadyError(),
    );
  }

  if (response.status !== httpStatusCode.created) {
    throw new RegistrationError(
      `${response.statusText} src/api/client/fetch/changeEmail.ts failed to changeEmailApply`,
    );
  }

  const responseBody = (await response.json()) as ChangeEmailApplyResponse;

  return createSuccessResult(responseBody);
};
