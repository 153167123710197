import {
  fortuneTellingUrl,
  type HttpsUrl,
  AppEnv,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import {
  AccountMyPageTemplate,
  MyPageContents,
} from '@nurse-senka/nurse-senka-web-ui';

import { AppHeader } from '../../components/AppHeader';
import { appUrl, portalUrl } from '../../constants/url';

import type { MetaTag } from '../../constants/metaTag';
import type { Information } from '../../domain/portal';
import type { LoggedInUser } from '../../domain/user';
import type { FC } from 'react';

export type TodayFortuneTellingResult = {
  zodiacSignName: string;
  // eslint-disable-next-line no-magic-numbers
  rank: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  detailUrl: HttpsUrl;
};

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  todayFortuneTellingResult?: TodayFortuneTellingResult;
  informationList?: Information[];
  insuranceSubscribed: boolean;
};

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'マイページ',
    url: appUrl.top,
  } as const,
];

const blockNavigationMyPageIndexLinks = {
  // TODO 後でSDKの関数から取得する
  bookmarkedArticlesUrl: 'https://knowledge.nurse-senka.jp/bookmarks',
  profileChangeUrl: '/my/profiles',
  mailMagazineSettingsUrl: '/my/mail-magazine/settings',
  logoutUrl: '/logout',
  helpUrl: `${portalUrl()}/helps`,
} as const;

const upperBannerList = [
  {
    id: 1,
    bannerUrl:
      'https://cdn-knowledge-assets.nurse-senka.jp/wp-content/uploads/2021/04/30100948/logo_tiara_web-5_2-min.png',
    contentUrl: 'https://knowledge.nurse-senka.jp/tiara_web/',
    altText: '看護情報誌ティアラWeb特集',
  } as const,
];

const bottomBannerList = [
  {
    id: 10,
    bannerUrl:
      'https://cdn-knowledge-assets.nurse-senka.jp/wp-content/uploads/2022/03/07104245/bnr_test2_s.png',
    contentUrl:
      'https://knowledge.nurse-senka.jp/sinjin_selection?br&_bdld=3bm%203X.oruIp6R.1678806256',
    altText: '看護学生のための合同就職説明会',
  } as const,
];

export const IndexTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  todayFortuneTellingResult,
  informationList,
  insuranceSubscribed,
}) => {
  const fortuneTellingResult = todayFortuneTellingResult || {
    zodiacSignName: '-',
    rank: 12,
    detailUrl: fortuneTellingUrl(appEnv),
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountMyPageTemplate
        pageHeader={{ title: 'Myページ' }}
        appEnv={appEnv}
        loggedInUser={loggedInUser}
        breadcrumbElements={breadcrumbElements}
        fortuneTellingResult={fortuneTellingResult}
        insuranceSubscribed={insuranceSubscribed}
        loading={false}
        upperBannerList={upperBannerList}
        bottomBannerList={bottomBannerList}
      >
        <MyPageContents
          loggedInUser={loggedInUser}
          appEnv={appEnv}
          news={informationList}
          blockNavigationMyPageIndexLinks={blockNavigationMyPageIndexLinks}
        />
      </AccountMyPageTemplate>
    </>
  );
};
