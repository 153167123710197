import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeFamilyStructureError } from '../../../domain/errors/ChangeFamilyStructureError';

import type { ChangeFamilyStructure } from '../../../domain/familyStructure';

export const changeFamilyStructure: ChangeFamilyStructure = async (request) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeFamilyStructure, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeFamilyStructureError();
  }
};
