import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { affiliations, type AffiliationId } from './affiliation';
import { employments, type EmploymentId } from './employment';
import { licenses, type LicenseId } from './license';

import type { AccessTokenValue } from './auth';
import type { LoggedInUser } from './user';

export type InitialSettingRequest = {
  employmentId: EmploymentId;
  affiliationIds: AffiliationId[];
  licenseIds?: LicenseId[];
};

export type InitialSettingRequestOnServer = InitialSettingRequest & {
  loggedInUser: LoggedInUser;
};

export const validateEmploymentId = (value: unknown): boolean => {
  if (typeof value === 'number') {
    if (employments.find((employment) => employment.id === value)) {
      return true;
    }
  }

  return false;
};

export const isEmploymentId = (value: unknown): value is EmploymentId =>
  validateEmploymentId(value);

export const isAffiliationId = (value: unknown): value is AffiliationId => {
  if (typeof value === 'number') {
    if (affiliations.find((affiliation) => affiliation.id === value)) {
      return true;
    }
  }

  return false;
};

export const isLicenseId = (value: unknown): value is LicenseId => {
  if (typeof value === 'number') {
    if (licenses.find((license) => license.id === value)) {
      return true;
    }
  }

  return false;
};

const affiliationIdValidationDefinition = affiliations.map((value) =>
  z.literal(value.id),
);

const licenseIdValidationDefinition = licenses.map((value) =>
  z.literal(value.id),
);

const initialSettingSchema = z.object({
  employmentId: z.number().refine((value) => validateEmploymentId(value)),
  affiliationIds: z
    .array(
      z.union(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        affiliationIdValidationDefinition,
      ),
    )
    .nonempty(),
  licenseIds: z
    .array(
      z.union(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        licenseIdValidationDefinition,
      ),
    )
    // eslint-disable-next-line no-magic-numbers
    .max(5)
    .nonempty()
    .optional(),
});

export const validateInitialSettingRequest = (value: unknown) =>
  validation(initialSettingSchema, value);

export const isInitialSettingRequest = (
  value: unknown,
): value is InitialSettingRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  return validateInitialSettingRequest(value).isValidate;
};

export type InitialSettingResponse = {
  loggedInUser: LoggedInUser;
};

export type InitialSettingOnClient = (
  request: InitialSettingRequest,
) => Promise<InitialSettingResponse>;

export type InitialSetting = (
  accessToken: AccessTokenValue,
  request: InitialSettingRequestOnServer,
) => Promise<InitialSettingResponse>;
