import { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import {
  AccountTemplate,
  PasswordResetChangeContents,
  CompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { useState, type ChangeEvent, FC, FormEvent } from 'react';
import { v4 } from 'uuid';

import { passwordResetComplete } from '../../api/client/fetch/passwordReset';
import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { MetaTag } from '../../constants/metaTag';
import { appUrl, portalUrl } from '../../constants/url';
import { createAuthorizationRequest } from '../../domain/auth';
import { PasswordResetCompleteSamePasswordError } from '../../domain/errors/PasswordResetCompleteSamePasswordError';
import {
  isPasswordResetCompleteRequest,
  PasswordResetToken,
} from '../../domain/passwordReset';
import { isFailureResult } from '../../domain/result';

const authorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

const pageTitle = 'パスワード再設定';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: pageTitle,
    url: appUrl.passwordResetComplete,
  } as const,
];

const backToLinks = {
  text: 'パスワードの再設定が完了しました',
  buttonText: 'ナース専科TOPへ戻る',
  url: portalUrl(),
};

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  passwordResetToken: PasswordResetToken;
  errorMessage?: string;
};

// eslint-disable-next-line max-lines-per-function
export const PasswordResetCompleteTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  passwordResetToken,
}) => {
  const [password, setPassword] = useState<string>();

  const [completed, setCompleted] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const inputPassword = event.target.value;

    setPassword(inputPassword);
  };

  // eslint-disable-next-line require-await
  const submit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const request = { passwordResetToken, password };
    if (isPasswordResetCompleteRequest(request)) {
      setLoading(true);
      const passwordResetCompleteResult = await passwordResetComplete(request);
      if (isFailureResult(passwordResetCompleteResult)) {
        const message =
          passwordResetCompleteResult.value instanceof
          PasswordResetCompleteSamePasswordError
            ? '変更前のパスワードと同じパスワードは設定できません。'
            : 'URLが無効です、お手数ですが最初からやり直して下さい。';

        setCompleted(false);
        setLoading(false);
        setErrorMessage(message);

        return;
      }

      setCompleted(true);
      setLoading(false);
      setErrorMessage('');
    } else {
      setCompleted(false);
      setLoading(false);
      setErrorMessage(
        'パスワードには「半角英数字をそれぞれ1種類以上含む8文字以上16文字以下」を指定してください。',
      );
    }
  };

  const eventHandlers = {
    changePassword,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      {completed ? (
        <AccountTemplate
          appEnv={appEnv}
          pageHeader={{ title: pageTitle }}
          breadcrumbElements={breadcrumbElements}
          authorizationRequest={authorizationRequest}
          isLoggedIn={false}
          loading={false}
        >
          <CompleteContents backToLinks={backToLinks} />
        </AccountTemplate>
      ) : (
        <AccountTemplate
          appEnv={appEnv}
          pageHeader={{ title: pageTitle }}
          breadcrumbElements={breadcrumbElements}
          authorizationRequest={authorizationRequest}
          isLoggedIn={false}
          loading={loading}
        >
          <PasswordResetChangeContents
            eventHandlers={eventHandlers}
            errorMessage={errorMessage}
          />
        </AccountTemplate>
      )}
    </>
  );
};
