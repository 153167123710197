import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeBirthdate } from '../../../domain/birthdate';
import { ChangeBirthdateError } from '../../../domain/errors/ChangeBirthdateError';

export const changeBirthdate: ChangeBirthdate = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeBirthdate, options);

  if (
    response.status === httpStatusCode.ok ||
    response.status === httpStatusCode.created
  ) {
    return;
  }

  throw new ChangeBirthdateError(
    `${response.statusText} src/api/client/fetch/cancelAccount.ts failed to cancelAccount`,
  );
};
