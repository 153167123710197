import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { AuthenticationFailedError } from '../../../domain/errors/AuthenticationFailedError';

import type { LoggedInUser, FetchLoggedInUser } from '../../../domain/user';

export const fetchLoggedInUser: FetchLoggedInUser = async () => {
  const options = {
    method: 'GET',
  };

  const response = await fetch(apiPath.users, options);
  if (response.status !== httpStatusCode.ok) {
    throw new AuthenticationFailedError();
  }

  return (await response.json()) as LoggedInUser;
};
