/* eslint-disable no-magic-numbers */
import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { createInitBirthYearList } from './birthdate';
import UsedEmailAlreadyError from './errors/UsedEmailAlreadyError';
import { LoginSessionToken } from './login';
import { prefectureIds, type PrefectureId } from './prefecture';
import { qualificationCodeList, type QualificationCode } from './qualification';
import {
  createGraduationYearList,
  schoolTypeIds,
  validateSchoolName,
  type GraduationYear,
  SchoolTypeId,
} from './school';
import {
  isEmail,
  type Email,
  LoggedInUser,
  validateName,
  validatePhoneNumber,
  validatePassword,
} from './user';

import type { Result } from './result';

export type RegistrationRequest = {
  qualificationCodeList: QualificationCode[];
  birthYear: number;
  birthMonth: number;
  birthday: number;
  prefectureId: PrefectureId;
  givenName: string;
  familyName: string;
  email: Email;
  password: string;
  graduationYear?: GraduationYear;
  schoolName?: string;
  schoolTypeId?: SchoolTypeId;
  phoneNumber?: string;
};

export type StudentRegistrationRequest = RegistrationRequest & {
  graduationYear: GraduationYear;
  schoolName: string;
  schoolTypeId: SchoolTypeId;
  phoneNumber: string;
  isReceiveHospitalNotices: boolean;
};

const qualificationCodeListValidationDefinition = qualificationCodeList.map(
  (value) => z.literal(value),
);

const prefectureIdValidationDefinition = prefectureIds.map((value) =>
  z.literal(value),
);

const birthYearList = createInitBirthYearList();

const registrationSchema = z.object({
  qualificationCodeList: z
    .array(
      z.union(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        qualificationCodeListValidationDefinition,
      ),
    )
    .nonempty(),
  birthYear: z
    .number()
    .min(birthYearList[0])
    .max(birthYearList[birthYearList.length - 1]),
  birthMonth: z.number().min(1),
  birthday: z.number().min(1).max(31),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  prefectureId: z.union(prefectureIdValidationDefinition),
  givenName: z.string().refine((value) => validateName(value)),
  familyName: z.string().refine((value) => validateName(value)),
  email: z.string().email(),
  password: z.string().refine((value) => validatePassword(value)),
});

const graduationYearList = createGraduationYearList();

const schoolTypeIdsValidationDefinition = schoolTypeIds.map((value) =>
  z.literal(value),
);

const studentOnlyRegistrationSchema = z.object({
  graduationYear: z
    .number()
    .min(graduationYearList[0])
    .max(graduationYearList[graduationYearList.length - 1]),
  schoolName: z.string().refine((value) => validateSchoolName(value)),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  schoolTypeId: z.union(schoolTypeIdsValidationDefinition),
  phoneNumber: z.string().refine((value) => validatePhoneNumber(value)),
  isReceiveHospitalNotices: z.boolean(),
});

const studentRegistrationSchema = registrationSchema.merge(
  studentOnlyRegistrationSchema,
);

export const isRegistrationRequest = (
  value: unknown,
): value is RegistrationRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const validationResult = validation(registrationSchema, value);
  if (!validationResult.isValidate) {
    return false;
  }

  const registrationRequest = value as RegistrationRequest;

  return isEmail(registrationRequest.email);
};

export const isStudentRegistrationRequest = (
  value: unknown,
): value is StudentRegistrationRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const validationResult = validation(studentRegistrationSchema, value);
  if (!validationResult.isValidate) {
    return false;
  }

  const registrationRequest = value as StudentRegistrationRequest;

  return isEmail(registrationRequest.email);
};

export const validateRegistrationRequest = (request: unknown) =>
  validation(registrationSchema, request);

export const validateStudentRegistrationRequest = (request: unknown) =>
  validation(studentRegistrationSchema, request);

export type RegistrationResponse = {
  loggedInUser: LoggedInUser;
  loginSessionToken: LoginSessionToken;
};

export type Registration = (
  request: RegistrationRequest,
) => Promise<Result<RegistrationResponse, UsedEmailAlreadyError>>;
