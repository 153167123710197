import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeScholarshipError } from '../../../domain/errors/ChangeScholarshipError';
import { ChangeScholarship } from '../../../domain/scholarship';

export const changeScholarship: ChangeScholarship = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeScholarship, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeScholarshipError();
  }
};
