import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeProfileError } from '../../../domain/errors/ChangeProfileError';

import type { ChangeProfile } from '../../../domain/user';

export const changeProfile: ChangeProfile = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeProfile, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeProfileError();
  }
};
