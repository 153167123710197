import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { AccountNotFoundError } from './errors/AccountNotFoundError';
import { AccountStatusNotEnabledError } from './errors/AccountStatusNotEnabledError';
import { InvalidPasswordResetTokenError } from './errors/InvalidPasswordResetTokenError';
import { PasswordResetCompleteSamePasswordError } from './errors/PasswordResetCompleteSamePasswordError';
import { PasswordResetTokenExpiredError } from './errors/PasswordResetTokenExpiredError';
import { PasswordResetTokenNotFoundError } from './errors/PasswordResetTokenNotFoundError';
import { validateLoginEmail } from './login';
import { validatePassword, type Email } from './user';

import type { AccessTokenValue } from './auth';
import type { Result } from './result';

const passwordResetApplySchema = z.object({
  email: z.string().refine((value) => validateLoginEmail(value)),
});

export type PasswordResetApplyRequest = {
  email: Email;
};

export type PasswordResetToken = string;

export type PasswordResetApplyResponse = {
  passwordResetToken: PasswordResetToken;
};

export type PasswordResetApply = (
  accessToken: AccessTokenValue,
  request: PasswordResetApplyRequest,
) => Promise<Result<PasswordResetApplyResponse, AccountNotFoundError>>;

export type PasswordResetApplyOnClient = (
  request: PasswordResetApplyRequest,
) => Promise<Result<PasswordResetApplyResponse, AccountNotFoundError>>;

const validatePasswordResetApplyRequest = (request: unknown) =>
  validation(passwordResetApplySchema, request);

export const isPasswordResetApplyRequest = (
  value: unknown,
): value is PasswordResetApplyRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const validationResult = validatePasswordResetApplyRequest(value);

  return validationResult.isValidate;
};

export type PasswordResetCompleteRequest = {
  passwordResetToken: PasswordResetToken;
  password: string;
};

// eslint-disable-next-line no-magic-numbers
const passwordResetTokenSchema = z.string().min(64).max(64);

const passwordResetCompleteSchema = z.object({
  passwordResetToken: passwordResetTokenSchema,
  password: z.string().refine((value) => validatePassword(value)),
});

export const validatePasswordResetCompleteRequest = (request: unknown) =>
  validation(passwordResetCompleteSchema, request);

export const isPasswordResetCompleteRequest = (
  value: unknown,
): value is PasswordResetCompleteRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  return validatePasswordResetCompleteRequest(value).isValidate;
};

type PasswordResetCompleteResponse = PasswordResetApplyResponse;

export type PasswordResetComplete = (
  accessToken: AccessTokenValue,
  request: PasswordResetCompleteRequest,
) => Promise<
  Result<
    PasswordResetCompleteResponse,
    | PasswordResetTokenExpiredError
    | PasswordResetTokenNotFoundError
    | InvalidPasswordResetTokenError
    | PasswordResetCompleteSamePasswordError
  >
>;

export type PasswordResetCompleteOnClient = (
  request: PasswordResetCompleteRequest,
) => Promise<
  Result<
    PasswordResetCompleteResponse,
    | PasswordResetTokenExpiredError
    | PasswordResetTokenNotFoundError
    | InvalidPasswordResetTokenError
    | PasswordResetCompleteSamePasswordError
  >
>;

type FindPasswordResetTokenRequest = {
  accessToken: AccessTokenValue;
  nowDate: Date;
  passwordResetToken: PasswordResetToken;
};

type FindPasswordResetTokenResponse = PasswordResetApplyResponse;

export type FindPasswordResetToken = (
  request: FindPasswordResetTokenRequest,
) => Promise<
  Result<
    FindPasswordResetTokenResponse,
    | AccountStatusNotEnabledError
    | InvalidPasswordResetTokenError
    | PasswordResetTokenNotFoundError
    | PasswordResetTokenExpiredError
  >
>;
