/* eslint-disable max-lines-per-function, max-lines */
// TODO ファイルが大きいので後でリファクタリングする
import {
  RegisterContents,
  AccountRegisterTemplate,
  type RegisterInvalidParams,
  AuthorizationRequest,
} from '@nurse-senka/nurse-senka-web-ui';
import { setCookie } from 'nookies';
import { useState, type FC, ChangeEvent, FormEvent } from 'react';
import { v4 } from 'uuid';
import { useSnapshot } from 'valtio';

import { registration } from '../../api/client/fetch/registration';
import { fetchSchoolNameSuggestions } from '../../api/client/fetch/school';
import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { appUrl } from '../../constants/url';
import {
  createAuthorizationRequest,
  createAuthorizationUrl,
} from '../../domain/auth';
import {
  createBirthdayList,
  createInitBirthMonthList,
  createInitBirthYearList,
  initBirthday,
} from '../../domain/birthdate';
import { isPrefectureId, prefectures } from '../../domain/prefecture';
import {
  isQualificationCode,
  qualifications,
  qualificationStudent,
} from '../../domain/qualification';
import {
  isRegistrationRequest,
  isStudentRegistrationRequest,
  validateRegistrationRequest,
  validateStudentRegistrationRequest,
} from '../../domain/registration';
import { isSuccessResult } from '../../domain/result';
import {
  createGraduationYearList,
  isGraduationYear,
  isSchoolTypeId,
  schoolTypes,
} from '../../domain/school';
import { formatName, isEmail } from '../../domain/user';
import {
  registrationFormStateSelector,
  updateBirthday,
  updateBirthdayList,
  updateBirthMonth,
  updateBirthYear,
  updateEmail,
  updateFamilyName,
  updateGivenName,
  updateGraduationYear,
  updatePassword,
  updatePhoneNumber,
  updatePrefectureId,
  updateQualificationCodeList,
  updateSchoolName,
  updateSchoolTypeId,
  updateIsReceiveHospitalNotices,
} from '../../stores/valtio/registrationForm';
import { addDay } from '../../utils/date';
import { sendRecommendedEvent } from '../../utils/gtm';

import type { MetaTag } from '../../constants/metaTag';
import type {
  AppEnv,
  HttpsUrl,
  BirthMonth,
} from '@nurse-senka/nurse-senka-frontend-sdk';

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  redirectUri?: HttpsUrl;
  initAuthorizationRequest?: AuthorizationRequest;
};

const prefectureAttributes = prefectures.map((prefecture) => ({
  key: prefecture.id,
  value: String(prefecture.id),
  label: prefecture.label,
}));

const schoolTypeAttributes = schoolTypes.map((schoolType) => ({
  key: schoolType.id,
  value: String(schoolType.id),
  label: schoolType.label,
}));

const birthYearAttributes = createInitBirthYearList().map((value) => ({
  key: value,
  value: String(value),
  label: String(value),
}));

const birthMonthAttributes = createInitBirthMonthList().map((value) => ({
  key: value,
  value: String(value),
  label: String(value),
}));

const namePlaceholders = {
  familyName: '那須',
  givenName: '恵子',
} as const;

const graduationYearAttributes = createGraduationYearList().map((value) => ({
  key: value,
  value: String(value),
  label: `${value}年`,
}));

const qualificationAttributes = qualifications.map((qualification) => ({
  id: String(qualification.code),
  name: 'qualificationCode',
  value: qualification.code,
  label: qualification.label,
}));

const defaultAuthorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.settingsInit,
  generateUniqueId: v4,
});

export const isRegisterInvalidParams = (
  value: unknown,
): value is RegisterInvalidParams => {
  if (Array.isArray(value)) {
    // eslint-disable-next-line no-magic-numbers
    if (value.length === 0) {
      return false;
    }

    // eslint-disable-next-line prefer-destructuring, no-magic-numbers
    const object = value[0];

    return Object.hasOwn(object, 'name') && Object.hasOwn(object, 'reason');
  }

  return false;
};

// 会員登録完了後にどこのURLに戻すかを決定している
const computeAfterAuthorizedRedirectUri = (
  isStudent: boolean,
  redirectUri?: HttpsUrl,
): HttpsUrl => {
  if (isStudent) {
    if (redirectUri) {
      return redirectUri;
    }

    return appUrl.registrationsComplete;
  }

  return appUrl.settingsInit;
};

export const RegistrationTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  redirectUri,
  initAuthorizationRequest,
}) => {
  const snap = useSnapshot(registrationFormStateSelector());
  const [invalidParams, setInvalidParams] = useState<RegisterInvalidParams>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const authorizationRequest =
    initAuthorizationRequest || defaultAuthorizationRequest;

  const changeQualificationsCheckBox = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedQualificationCode = Number(event.target.value);

    if (!isQualificationCode(selectedQualificationCode)) {
      return;
    }

    updateQualificationCodeList(selectedQualificationCode);
  };

  const changeBirthYear = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedBirthYear = Number(event.target.value);

    updateBirthYear(selectedBirthYear);

    const createdBirthdayList = createBirthdayList(
      selectedBirthYear,
      snap.birthMonth,
    );

    updateBirthdayList(createdBirthdayList);

    // 選択されている誕生日より生成された日の合計が大きい場合強制的に1日にリセットする
    if (createdBirthdayList.length < snap.birthday) {
      updateBirthday(initBirthday);
    }
  };

  const changeBirthMonth = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedBirthMonth = Number(event.target.value) as BirthMonth;

    updateBirthMonth(selectedBirthMonth);

    const createdBirthdayList = createBirthdayList(
      snap.birthYear,
      selectedBirthMonth,
    );

    updateBirthdayList(createdBirthdayList);

    // 選択されている誕生日より生成された日の合計が大きい場合強制的に1日にリセットする
    if (createdBirthdayList.length < snap.birthday) {
      updateBirthday(initBirthday);
    }
  };

  const changeBirthday = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedBirthday = Number(event.target.value);

    updateBirthday(selectedBirthday);
  };

  const changePrefectureSelectBox = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedPrefectureId = Number(event.target.value);

    if (isPrefectureId(selectedPrefectureId)) {
      updatePrefectureId(selectedPrefectureId);
    }
  };

  const changeFamilyName = (event: ChangeEvent<HTMLInputElement>) => {
    const inputFamilyName = event.target.value;

    updateFamilyName(inputFamilyName);
  };

  const changeGivenName = (event: ChangeEvent<HTMLInputElement>) => {
    const inputGivenName = event.target.value;

    updateGivenName(inputGivenName);
  };

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;

    if (isEmail(inputEmail)) {
      updateEmail(inputEmail);
    }
  };

  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const inputPassword = event.target.value;

    updatePassword(inputPassword);
  };

  const changeGraduationYear = (event: ChangeEvent<HTMLSelectElement>) => {
    const graduationYear = Number(event.target.value);

    if (isGraduationYear(graduationYear)) {
      updateGraduationYear(graduationYear);
    }
  };

  const afterClickSchoolNameSuggestionText = async (
    suggestion: string,
    // eslint-disable-next-line require-await
  ): Promise<void> => {
    updateSchoolName(suggestion);
  };

  const changeSchoolType = (event: ChangeEvent<HTMLSelectElement>) => {
    const schoolTypeId = Number(event.target.value);
    if (isSchoolTypeId(schoolTypeId)) {
      updateSchoolTypeId(schoolTypeId);
    }
  };

  const changePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const inputPhoneNumber = event.target.value;

    updatePhoneNumber(inputPhoneNumber);
  };

  const changeIsReceiveHospitalNotices = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    updateIsReceiveHospitalNotices(event.target.checked);
  };

  const birthdayList = snap.birthdayList as number[];

  const birthdayAttributes = birthdayList.map((value) => ({
    key: value,
    value: String(value),
    label: String(value),
  }));

  const shouldShowSchoolForm = snap.qualificationCodeList?.includes(
    qualificationStudent.code,
  );

  const shouldShowPhoneNumberForm = snap.qualificationCodeList?.includes(
    qualificationStudent.code,
  );

  const isStudent =
    shouldShowSchoolForm === true && shouldShowPhoneNumberForm === true;

  const {
    qualificationCodeList,
    birthYear,
    birthMonth,
    birthday,
    prefectureId,
    givenName,
    familyName,
    email,
    password,
    graduationYear,
    schoolName,
    schoolTypeId,
    phoneNumber,
    isReceiveHospitalNotices,
  } = snap;

  const submit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const registrationRequest = {
      qualificationCodeList,
      birthYear,
      birthMonth,
      birthday,
      prefectureId,
      givenName: formatName(givenName),
      familyName: formatName(familyName),
      email,
      password,
      graduationYear,
      schoolName,
      schoolTypeId,
      phoneNumber,
      isReceiveHospitalNotices,
    };

    const validator = isStudent
      ? validateStudentRegistrationRequest
      : validateRegistrationRequest;

    const typeGuardFunc = isStudent
      ? isStudentRegistrationRequest
      : isRegistrationRequest;

    const validationResult = validator(registrationRequest);
    try {
      if (typeGuardFunc(registrationRequest) && validationResult.isValidate) {
        setErrorMessage('');
        setLoading(true);

        const registrationResult = await registration(registrationRequest);
        if (isSuccessResult(registrationResult)) {
          sendRecommendedEvent('sign_up');

          // Cookieが先に消えてしまう事を防ぐため、トークンの有効期限より少し長めの値を設定しておく
          const loginSessionTokenCookieExpires = 15;

          setCookie(
            null,
            'login_session_token',
            registrationResult.value.loginSessionToken,
            {
              domain:
                appEnv === 'production' ? 'nurse-senka.jp' : 'smsc-nsc.com',
              secure: appEnv === 'production',
              httpOnly: false,
              expires: addDay(new Date(), loginSessionTokenCookieExpires),
              path: '/',
            },
          );

          const afterAuthorizedRedirectUri = computeAfterAuthorizedRedirectUri(
            isStudent,
            redirectUri,
          );

          const queryParams = {
            client_id: String(authorizationRequest.clientId),
            redirect_uri: afterAuthorizedRedirectUri,
            state: authorizationRequest.state,
            nonce: authorizationRequest.nonce,
          };

          location.href = createAuthorizationUrl(queryParams);
        } else {
          setLoading(false);
          setErrorMessage('そのメールアドレスは既に登録されています。');
        }
      } else {
        const registerInvalidParam = validationResult.invalidParams?.map(
          (value) => {
            switch (value.name) {
              case 'qualificationCodeList':
                return {
                  name: 'qualificationCodeList',
                  reason: '保有資格を選択して下さい。',
                };
              case 'birthYear':
              case 'birthMonth':
              case 'birthday':
                return {
                  name: 'birthdate',
                  reason: '生年月日を正しく入力して下さい。',
                };
              case 'prefectureId':
                return {
                  name: 'prefectureId',
                  reason: '都道府県を正しく入力して下さい。',
                };
              case 'givenName':
              case 'familyName':
                return {
                  name: 'name',
                  reason: '名前を正しく入力して下さい。',
                };
              case 'email':
                return {
                  name: 'email',
                  reason: 'メールアドレスを正しく入力して下さい。',
                };
              case 'password':
                return {
                  name: 'password',
                  reason:
                    'パスワードには「半角英数字をそれぞれ1種類以上含む8文字以上16文字以下」を指定してください。',
                };
              case 'graduationYear':
                return {
                  name: 'graduationYear',
                  reason: '卒業予定年を選択して下さい。',
                };
              case 'schoolName':
                return {
                  name: 'schoolName',
                  reason: '学校名を正しく入力して下さい。',
                };
              case 'schoolTypeId':
                return {
                  name: 'schoolTypeId',
                  reason: '学校タイプを正しく入力して下さい。',
                };
              case 'phoneNumber':
                return {
                  name: 'phoneNumber',
                  reason: '電話番号を正しく入力して下さい。',
                };
              default:
                return {
                  name: 'email',
                  reason: 'メールアドレスを正しく入力して下さい。',
                };
            }
          },
        );

        if (isRegisterInvalidParams(registerInvalidParam)) {
          setLoading(false);
          setErrorMessage('');
          setInvalidParams(registerInvalidParam);
        }
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(
        '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
      );
    }
  };

  const eventHandlers = {
    changeQualificationsCheckBox,
    changeBirthYear,
    changeBirthMonth,
    changeBirthday,
    changePrefectureSelectBox,
    changeFamilyName,
    changeGivenName,
    changeEmail,
    changePassword,
    changeGraduationYear,
    changeSchoolType,
    changePhoneNumber,
    fetchSchoolNameSuggestions,
    afterClickSchoolNameSuggestionText,
    changeIsReceiveHospitalNotices,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountRegisterTemplate
        appEnv={appEnv}
        pageHeader={{ title: '会員登録' }}
        loading={loading}
        isLoggedIn={false}
      >
        <RegisterContents
          appEnv={appEnv}
          prefectureAttributes={prefectureAttributes}
          schoolTypeAttributes={schoolTypeAttributes}
          birthYearAttributes={birthYearAttributes}
          birthMonthAttributes={birthMonthAttributes}
          birthdayAttributes={birthdayAttributes}
          namePlaceholders={namePlaceholders}
          graduationYearAttributes={graduationYearAttributes}
          qualificationAttributes={qualificationAttributes}
          authorizationRequest={authorizationRequest}
          isStudent={isStudent}
          isReceiveHospitalNotices={isReceiveHospitalNotices}
          isReceiveHospitalNoticesLabelText="病院からのお知らせメールを受け取る"
          eventHandlers={eventHandlers}
          invalidParams={invalidParams}
          errorMessage={errorMessage}
          selectedBirthYear={String(birthYear)}
        />
      </AccountRegisterTemplate>
    </>
  );
};
