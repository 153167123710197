import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { EmploymentIdUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';
import { z } from 'zod';

import { validateEmploymentId } from './initialSetting';

// eslint-disable-next-line no-magic-numbers
const employmentIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14] as const;

export type EmploymentId = (typeof employmentIds)[number];

const employmentLabels = [
  '大学病院',
  '国・公的病院',
  '法人・個人病院',
  'クリニック',
  '助産所',
  '訪問看護ステーション',
  '介護保険施設',
  '社会福祉施設',
  '保健所・保健センター',
  '企業',
  '教育機関',
  '保育園・幼稚園',
  'その他',
  '離職中',
] as const;

export type EmploymentLabel = (typeof employmentLabels)[number];

type Employment = {
  id: EmploymentId;
  label: EmploymentLabel;
};

export const employments: Employment[] = employmentIds.map((value, index) => ({
  id: value,
  label: employmentLabels[index],
}));

export type ChangeEmploymentRequest = EmploymentIdUpdaterRequest;

export const changeEmploymentRequestSchema = z.object({
  employmentId: z.number().refine((value) => validateEmploymentId(value)),
});

export const isChangeEmploymentRequest = (
  value: unknown,
): value is ChangeEmploymentRequest =>
  validation(changeEmploymentRequestSchema, value).isValidate;

export type ChangeEmployment = (
  request: ChangeEmploymentRequest,
) => Promise<void>;
