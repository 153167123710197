import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import {
  QualificationCodeListUpdaterRequest,
  QualificationCodeListValidator,
} from '@nurse-senka/nurse-senka-web-ui';
import { z } from 'zod';

import QualificationNotFoundError from './errors/QualificationNotFoundError';
import { createFailureResult, createSuccessResult, Result } from './result';
// eslint-disable-next-line no-magic-numbers
export const qualificationCodeList = [1, 2, 3, 4, 6, 7] as const;

export type QualificationCode = (typeof qualificationCodeList)[number];

export const isQualificationCode = (
  value: unknown,
): value is QualificationCode => {
  if (typeof value !== 'number') {
    return false;
  }

  return qualificationCodeList.includes(value as QualificationCode);
};

const qualificationLabels = [
  '看護師',
  '准看護師',
  '保健師',
  '助産師',
  'その他医療職',
  '看護学生',
] as const;

export type QualificationLabel = (typeof qualificationLabels)[number];

export type Qualification = {
  code: QualificationCode;
  label: QualificationLabel;
};

export const qualifications: Qualification[] = qualificationCodeList.map(
  (value, index) => ({
    code: value,
    label: qualificationLabels[index],
  }),
);

export const extractQualifications = (
  code: QualificationCode,
): Result<Qualification, QualificationNotFoundError> => {
  const extractedQualification = qualifications.find(
    (qualification) => qualification.code === code,
  );

  if (extractedQualification) {
    return createSuccessResult(extractedQualification);
  }

  return createFailureResult(new QualificationNotFoundError());
};

export const qualificationStudent: Qualification = {
  code: 7,
  label: '看護学生',
};

export type ChangeQualificationsRequest = QualificationCodeListUpdaterRequest;

const changeQualificationsRequestSchema = z.object({
  qualificationCodeList: z
    .number()
    .array()
    .nonempty('保有資格を正しく設定して下さい。'),
});

export const validateQualificationsRequest: QualificationCodeListValidator =
  // eslint-disable-next-line require-await
  async (value: unknown) =>
    validation(changeQualificationsRequestSchema, value);

export const isQualificationsRequest = (
  value: unknown,
): value is ChangeQualificationsRequest =>
  validation(changeQualificationsRequestSchema, value).isValidate;

export type ChangeQualifications = (
  request: ChangeQualificationsRequest,
) => Promise<void>;
