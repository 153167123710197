import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { AuthenticationFailedError } from './errors/AuthenticationFailedError';
import { type Email, isEmail, Sub } from './user';

import type { Result } from './result';

export type LoginRequest = {
  email: Email;
  password: string;
};

export const validateLoginEmail = (value: string): boolean => isEmail(value);

export const validateLoginPassword = (value: string): boolean => {
  if (value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/u)) {
    // eslint-disable-next-line no-magic-numbers
    if (value.length <= 100) {
      return true;
    }
  }

  return false;
};

const loginSchema = z.object({
  email: z.string().refine((value) => validateLoginEmail(value)),
  password: z.string().refine((value) => validateLoginPassword(value)),
});

export const validateLoginRequest = (request: unknown) =>
  validation(loginSchema, request);

export const isLoginRequest = (value: unknown): value is LoginRequest => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const validationResult = validation(loginSchema, value);

  return validationResult.isValidate;
};

export type LoginSessionToken = string;

export type LoginResponse = {
  loginSessionToken: LoginSessionToken;
};

export type Login = (
  request: LoginRequest,
) => Promise<Result<LoginResponse, AuthenticationFailedError>>;

type FindAndVerifyLoginSessionTokenRequest = {
  loginSessionToken: LoginSessionToken;
};

type FindAndVerifyLoginSessionTokenResponse = {
  loginSessionToken: LoginSessionToken;
  sub: Sub;
};

// 検証済の loginSessionToken を返す、ただしOIDC関連のトークンを payload に含まない
export type FindAndVerifyLoginSessionToken = (
  request: FindAndVerifyLoginSessionTokenRequest,
) => Promise<
  Result<FindAndVerifyLoginSessionTokenResponse, AuthenticationFailedError>
>;

// ログイン中のユーザーが持っているCookie
export type LoggedInUserCookie = {
  user_id?: string;
  login_session_token?: string;
};

// Cookie login_session_token のpayloadをデコードした結果
export type LoginSessionTokenPayload = {
  iss: 'https://account-api.nurse-senka.jp';
  sub: Sub;
  aud: 'https://nurse-senka.jp';
  iat: number;
  auth_time: number;
  exp: number;
  account_status: string;
  uuid: string;
  tokens: {
    scope: string;
    expires_in: number;
    token_type: 'Bearer';
    refresh_token: string;
    id_token: string;
    access_token: string;
  };
  login_session_token_exp: number;
};

const hasAccountStatus = (
  value: unknown,
): value is { account_status: string } => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return 'account_status' in value;
};

const hasUuid = (value: unknown): value is { uuid: string } => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return 'uuid' in value;
};

type LoginSessionSecretKey = string;

type VerifyJwtToken = (loginSessionToken: string, secretKey: string) => unknown;

type UnixTimeFormatter = (targetDate: Date) => number;

type VerifyLoginSessionTokenRequest = {
  loginSessionToken: LoginSessionToken;
  loginSessionSecretKey: LoginSessionSecretKey;
  verifyJwtToken: VerifyJwtToken;
  nowDate: Date;
  unixTimeFormatter: UnixTimeFormatter;
};

type VerifyLoginSessionTokenResponse = {
  verified: boolean;
  needsRefreshToken: boolean;
  payload?: {
    accessToken: string;
    idToken: string;
    refreshToken: string;
    expired: number;
  };
};

export const isLoginSessionTokenPayload = (
  value: unknown,
): value is LoginSessionTokenPayload => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  return hasAccountStatus(value) && hasUuid(value);
};
// この関数はサーバーサイドでしか利用出来ない
export const verifyLoginSessionToken = (
  request: VerifyLoginSessionTokenRequest,
): VerifyLoginSessionTokenResponse => {
  try {
    const payload = request.verifyJwtToken(
      request.loginSessionToken,
      request.loginSessionSecretKey,
    );

    if (!isLoginSessionTokenPayload(payload)) {
      return {
        verified: false,
        needsRefreshToken: false,
      };
    }

    const nowUnixTime = request.unixTimeFormatter(request.nowDate);

    if (payload.login_session_token_exp < nowUnixTime) {
      return {
        verified: false,
        needsRefreshToken: false,
      };
    }

    const dailySeconds = 86400;
    const needsRefreshToken =
      payload.login_session_token_exp - nowUnixTime <= dailySeconds;

    const verified = true;

    return {
      verified,
      needsRefreshToken,
      payload: {
        accessToken: payload.tokens.access_token,
        idToken: payload.tokens.id_token,
        refreshToken: payload.tokens.refresh_token,
        expired: payload.login_session_token_exp,
      },
    };
  } catch (error) {
    // TODO このブロックに入った時はエラー内容を精査した上で場合によってはSlackに通知したほうが良いかも
    return {
      verified: false,
      needsRefreshToken: false,
    };
  }
};
