import {
  type LoggedInUser as OrgLoggedInUser,
  HttpsUrl,
  validation,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { mbStrLen } from '../utils/mbString';
import { symbolIncludes } from '../utils/validation';

import type {
  InitAcademicBackground,
  InitBasicInfo,
  InitQualificationsAndWork,
  NameUpdaterRequest,
} from '@nurse-senka/nurse-senka-web-ui';

export type Sub = number;

export type PreferredUsername = string;

export type PictureUrl = HttpsUrl;

export type Birthdate = `${string}-${string}-${string}`;

export type LoggedInUser = OrgLoggedInUser;

export type Occupation =
  | 'nurse'
  | 'practical_nurse'
  | 'student'
  | 'student_with_licenses'
  | 'other';

export type Email = `${string}@${string}`;

export const isEmail = (value: unknown): value is Email => {
  if (typeof value !== 'string') {
    return false;
  }

  return value.includes('@');
};

export const formatEmail = (email: Email): Email =>
  email.trim().toLowerCase() as Email;

export const formatName = (name: string): string => name.trim();

export const validateName = (value: string) => {
  const length = mbStrLen(value);

  // eslint-disable-next-line no-magic-numbers
  if (length < 1 || length > 100) {
    return false;
  }

  return !symbolIncludes(value);
};

export const validateNameKana = (value: string) => {
  const length = mbStrLen(value);

  // eslint-disable-next-line no-magic-numbers
  if (length < 1 || length > 100) {
    return false;
  }

  if (value.match(/^[ぁ-んー]*$/u)) {
    return true;
  }

  return false;
};

export const validatePhoneNumber = (value: string): boolean => {
  if (!value.match(/^[0-9]+$/u)) {
    return false;
  }

  // eslint-disable-next-line no-magic-numbers
  return value.length === 10 || value.length === 11;
};

export const validatePassword = (value: string): boolean => {
  // eslint-disable-next-line require-unicode-regexp
  if (value.match(/^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,16}$/i)) {
    return true;
  }

  return false;
};

const changePreferredUsernameSchema = z.object({
  preferredUsername: z.string().refine((value) => validateName(value)),
});

type ChangePreferredUsernameRequest = {
  preferredUsername: PreferredUsername;
};

export const isChangePreferredUsernameRequest = (
  value: unknown,
): value is ChangePreferredUsernameRequest =>
  validation(changePreferredUsernameSchema, value).isValidate;

export type ChangePreferredUsername = (
  request: ChangePreferredUsernameRequest,
) => Promise<void>;

export type ChangeProfileRequest = {
  profileImage?: string;
  profileImageExtension?: string;
  selfIntroduction?: string;
};

export const changeProfileRequestSchema = z.object({
  profileImage: z.string().optional(),
  profileImageExtension: z.enum(['.png', '.jpg', '.jpeg']).optional(),
  selfIntroduction: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .min(5, '自己紹介は最低5文字以上入力して下さい。')
    // eslint-disable-next-line no-magic-numbers
    .max(500, '自己紹介は500文字以内で入力して下さい。')
    .optional(),
});

export const isChangeProfileRequest = (
  value: unknown,
): value is ChangeProfileRequest =>
  validation(changeProfileRequestSchema, value).isValidate;

export type ChangeProfile = (request: ChangeProfileRequest) => Promise<void>;

export type FetchLoggedInUser = () => Promise<LoggedInUser>;

export type FetchInitUserAttribute = (sub: Sub) => Promise<{
  basicInfo: InitBasicInfo;
  qualificationsAndWork: InitQualificationsAndWork;
  academicBackground: InitAcademicBackground;
}>;

// eslint-disable-next-line no-magic-numbers
const genderIds = [0, 1, 2, 9] as const;

export type GenderId = (typeof genderIds)[number];

export const changeNameRequestSchema = z.object({
  givenName: z
    .string()
    .refine((value) => validateName(value), '名を入力して下さい。'),
  familyName: z
    .string()
    .refine((value) => validateName(value), '姓を入力して下さい。'),
  givenNameKana: z
    .string()
    .refine(
      (value) => validateNameKana(value),
      'ふりがな（名）をひらがなで入力して下さい。',
    ),
  familyNameKana: z
    .string()
    .refine(
      (value) => validateNameKana(value),
      'ふりがな（姓）をひらがなで入力して下さい。',
    ),
});

export const validateChangeNameRequest = (value: unknown) =>
  validation(changeNameRequestSchema, value);

export type ChangeNameRequest = NameUpdaterRequest;

export const isChangeNameRequest = (
  value: unknown,
): value is ChangeNameRequest => validateChangeNameRequest(value).isValidate;

export type ChangeName = (request: ChangeNameRequest) => Promise<void>;
