import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeEmploymentError } from '../../../domain/errors/ChangeEmploymentError';

import type { ChangeEmployment } from '../../../domain/employment';

export const changeEmployment: ChangeEmployment = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeEmployment, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeEmploymentError(
      `${response.statusText} src/api/client/fetch/employment.ts failed to changeEmployment`,
    );
  }
};
