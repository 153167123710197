/* eslint-disable no-magic-numbers */
import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import type { LicenseIdsUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export const licenseIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39,
] as const;

export type LicenseId = (typeof licenseIds)[number];

const licenseLabels = [
  '救急看護',
  '皮膚・排泄ケア',
  '集中ケア',
  '緩和ケア',
  'がん化学療法看護',
  'がん性疼痛看護',
  '訪問看護',
  '感染管理',
  '糖尿病看護',
  '不妊症看護',
  '新生児集中ケア',
  '透析看護',
  '手術看護',
  '乳がん看護',
  '摂食・嚥下障害看護',
  '小児救急看護',
  '認知症看護',
  '脳卒中リハビリテーション看護',
  'がん放射線療法看護',
  '慢性呼吸器疾患看護',
  '慢性心不全看護',
  'がん看護',
  '精神看護',
  '地域看護',
  '老人看護',
  '小児看護',
  '母性看護',
  '慢性疾患看護',
  '急性・重症患者看護',
  '感染症看護',
  '家族支援',
  '在宅看護',
  '遺伝看護',
  '災害看護',
  '特定行為研修の修了者',
  '診療看護師',
  '認定看護管理者（ファースト）',
  '認定看護管理者（セカンド）',
  '認定看護管理者（サード）',
];

export type LicenseLabel = (typeof licenseLabels)[number];

export type License = {
  id: LicenseId;
  label: LicenseLabel;
};

export const licenses: License[] = licenseIds.map((value, index) => ({
  id: value,
  label: licenseLabels[index],
}));

export const changeLicenseRequestSchema = z.object({
  licenseIds: z
    .number()
    .array()
    .nonempty('認定資格を正しく設定して下さい。')
    .max(5, '認定資格は5つまでしか選択出来ません。'),
});

export type ChangeLicenseRequest = LicenseIdsUpdaterRequest;

export const isChangeLicenseRequest = (
  value: unknown,
): value is ChangeLicenseRequest =>
  validation(changeLicenseRequestSchema, value).isValidate;

export type ChangeLicense = (request: ChangeLicenseRequest) => Promise<void>;
