import { type AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import {
  AccountTemplate,
  CompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { type FC } from 'react';
import { v4 } from 'uuid';

import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { MetaTag } from '../../constants/metaTag';
import { appUrl, portalUrl } from '../../constants/url';
import { createAuthorizationRequest } from '../../domain/auth';

const authorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

const pageTitle = 'メルマガ解除';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: pageTitle,
    url: appUrl.mailMagazineUnsubscribe,
  } as const,
];

const backToLinks = {
  text: 'メルマガの解除が完了しました',
  buttonText: 'ナース専科TOPへ戻る',
  url: portalUrl(),
};

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
};

// eslint-disable-next-line max-lines-per-function
export const MailMagazineUnsubscribeTemplate: FC<Props> = ({
  appEnv,
  metaTag,
}) => (
  <>
    <AppHeader metaTag={metaTag} noIndex={false} />
    <AccountTemplate
      appEnv={appEnv}
      pageHeader={{ title: pageTitle }}
      breadcrumbElements={breadcrumbElements}
      authorizationRequest={authorizationRequest}
      isLoggedIn={false}
      loading={false}
    >
      <CompleteContents backToLinks={backToLinks} />
    </AccountTemplate>
  </>
);
