import {
  createInitBirthYearList as orgCreateInitBirthYearList,
  createInitBirthMonthList as orgCreateInitBirthMonthList,
  createBirthdayList as orgCreateBirthdayList,
  createBirthdate as orgCreateBirthdate,
  type Birthdate,
  validation,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { isValidDate } from '../utils/date';

export const initBirthYear = 1960;

export const defaultBirthYear = 2000;

export const initBirthMonth = 1;

export const createInitBirthYearList = orgCreateInitBirthYearList;

export const createInitBirthMonthList = orgCreateInitBirthMonthList;

export const initBirthday = 1;

const defaultEndDay = 31;

export const createDayList = (endDay: number = defaultEndDay): number[] => {
  const startDay = initBirthday;

  const birthdayList = [];

  // eslint-disable-next-line id-length, no-plusplus
  for (let i = startDay; i <= endDay; i++) {
    birthdayList.push(i);
  }

  return birthdayList;
};

// 誕生年と誕生月から日付のリストを生成する
export const createBirthdayList = orgCreateBirthdayList;

export const createBirthdate = orgCreateBirthdate;

export type ChangeBirthdateRequest = {
  birthdate: Birthdate;
};

export const changeBirthdateRequestSchema = z.object({
  birthdate: z
    .string()
    .refine(
      (value) => isValidDate(value),
      '誕生日は正しい日付を入力して下さい。',
    ),
});

export const isChangeBirthdateRequest = (
  value: unknown,
): value is ChangeBirthdateRequest =>
  validation(changeBirthdateRequestSchema, value).isValidate;

export type ChangeBirthdate = (
  request: ChangeBirthdateRequest,
) => Promise<void>;
