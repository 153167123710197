import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { validatePhoneNumber } from './user';

import type { PhoneNumberUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export const changePhoneNumberSchema = z.object({
  phoneNumber: z
    .string()
    .refine(
      (value) => validatePhoneNumber(value),
      '電話番号はハイフンなし10桁か11桁で入力して下さい。',
    ),
});

type ChangePhoneNumberRequest = PhoneNumberUpdaterRequest;

export const isChangePhoneNumberRequest = (
  value: unknown,
): value is ChangePhoneNumberRequest =>
  validation(changePhoneNumberSchema, value).isValidate;

export type ChangePhoneNumber = (
  request: ChangePhoneNumberRequest,
) => Promise<void>;
