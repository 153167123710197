import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeQualificationsError } from '../../../domain/errors/ChangeQualificationsError';
import { ChangeQualifications } from '../../../domain/qualification';

export const changeQualifications: ChangeQualifications = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeQualifications, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeQualificationsError(
      `${response.statusText} src/api/client/fetch/qualification.ts failed to changeQualifications`,
    );
  }
};
