declare global {
  interface Window {
    dataLayer: Array<Record<string, unknown>>;
  }
}

type RecommendedEvent = 'login' | 'sign_up';

export const sendRecommendedEvent = (event: RecommendedEvent): void => {
  window.dataLayer.push({
    event,
  });
};
