import { AppEnv, isAppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import { GoogleTagManagerId } from '@nurse-senka/nurse-senka-web-ui';

const isGoogleTagManagerId = (value: unknown): value is GoogleTagManagerId => {
  if (typeof value !== 'string') {
    return false;
  }

  const startPosition = 0;

  const endPosition = 4;

  return value.slice(startPosition, endPosition) === 'GTM-';
};

// eslint-disable-next-line import/prefer-default-export
export const googleTagManagerId = (): GoogleTagManagerId => {
  if (isGoogleTagManagerId(process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID)) {
    return process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID;
  }

  return 'GTM-TRR9P9P';
};

const defaultOidcClientId = 5925253577102;

export const oidcClientId = (): number =>
  process.env.NEXT_PUBLIC_OIDC_CLIENT_ID
    ? Number(process.env.NEXT_PUBLIC_OIDC_CLIENT_ID)
    : defaultOidcClientId;

export const appEnv = (): AppEnv => {
  if (isAppEnv(process.env.NEXT_PUBLIC_APP_ENV)) {
    return process.env.NEXT_PUBLIC_APP_ENV;
  }

  return 'production';
};
