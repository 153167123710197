import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeSchoolError } from '../../../domain/errors/ChangeSchoolError';

import type {
  FetchSchoolNameSuggestions,
  ChangeSchool,
} from '../../../domain/school';
import type { FetchSchoolNamesResponse } from '../../../pages/api/schools';

export const fetchSchoolNameSuggestions: FetchSchoolNameSuggestions = async (
  schoolName: string,
): Promise<string[]> => {
  const options = {
    method: 'GET',
  };

  const response = await fetch(
    `${apiPath.fetchSchoolNames}?${new URLSearchParams({ schoolName })}`,
    options,
  );

  const responseBody = (await response.json()) as FetchSchoolNamesResponse;

  return responseBody.schoolNames;
};

export const changeSchool: ChangeSchool = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeSchool, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeSchoolError();
  }
};
