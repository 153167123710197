import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import RegistrationError from '../../../domain/errors/RegistrationError';
import UsedEmailAlreadyError from '../../../domain/errors/UsedEmailAlreadyError';
import {
  Registration,
  RegistrationResponse,
} from '../../../domain/registration';
import {
  createFailureResult,
  createSuccessResult,
} from '../../../domain/result';

export const registration: Registration = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.registrations, options);

  if (response.status === httpStatusCode.conflict) {
    return createFailureResult<UsedEmailAlreadyError>(
      new UsedEmailAlreadyError(),
    );
  }

  if (response.status !== httpStatusCode.created) {
    throw new RegistrationError(
      `${response.statusText} src/api/client/fetch/registration.ts failed to registration`,
    );
  }

  const responseBody = (await response.json()) as RegistrationResponse;

  return createSuccessResult(responseBody);
};
