/* eslint-disable no-magic-numbers */
import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { ActivityAreaIdsUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';
import { z } from 'zod';

const activityAreaIds = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28,
] as const;

export type ActivityAreaId = (typeof activityAreaIds)[number];

const activityAreaLabels = [
  'がん',
  '透析',
  '糖尿',
  '救急',
  '手術',
  'リウマチ',
  '骨粗しょう症',
  'リハビリテーション',
  '助産・母性',
  '小児',
  '療育',
  'アレルギー',
  '栄養サポート',
  '口腔ケア',
  '摂食・嚥下',
  '感染管理',
  '褥瘡',
  '地域',
  '呼吸',
  '緩和ケア',
  '精神',
  '認知症',
  '老年看護',
  'ターミナルケア',
  'フットケア',
  '教育・マネジメント',
  'HIV',
  'その他',
] as const;

type ActivityAreaLabel = (typeof activityAreaLabels)[number];

export type ActivityArea = {
  id: ActivityAreaId;
  label: ActivityAreaLabel;
};

export const activityAreaList: ActivityArea[] = activityAreaIds.map(
  (value, index) => ({
    id: value,
    label: activityAreaLabels[index],
  }),
);

export type ChangeActivityAreaRequest = ActivityAreaIdsUpdaterRequest;

export const changeActivityAreaRequestSchema = z.object({
  activityAreaIds: z
    .number()
    .array()
    .nonempty('活動領域を正しく選択して下さい。'),
});

export const isChangeActivityAreaRequest = (
  value: unknown,
): value is ChangeActivityAreaRequest =>
  validation(changeActivityAreaRequestSchema, value).isValidate;

export type ChangeActivity = (
  request: ChangeActivityAreaRequest,
) => Promise<void>;
