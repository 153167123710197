import {
  AccountTemplate,
  ErrorReturnContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { v4 } from 'uuid';

import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { appUrl } from '../../constants/url';
import { createAuthorizationRequest } from '../../domain/auth';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv, HttpsUrl } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

const authorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  breadcrumbElements: {
    type: 'listItem';
    order: number;
    text: string;
    url: HttpsUrl;
  }[];
  errorMessage: string;
  retryLinks: {
    text: string;
    url: HttpsUrl;
  };
  loggedInUser?: LoggedInUser;
};

export const ErrorTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  breadcrumbElements,
  errorMessage,
  retryLinks,
  loggedInUser,
}) => (
  <>
    <AppHeader metaTag={metaTag} noIndex={true} />
    <AccountTemplate
      appEnv={appEnv}
      pageHeader={{ title: 'エラーが発生しました' }}
      breadcrumbElements={breadcrumbElements}
      loggedInUser={loggedInUser}
      loading={false}
      isLoggedIn={Boolean(loggedInUser)}
      authorizationRequest={authorizationRequest}
    >
      <ErrorReturnContents
        errorMessage={errorMessage}
        retryLinks={retryLinks}
      />
    </AccountTemplate>
  </>
);
