import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import type { CustomLicenseUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export type ChangeCustomLicenseRequest = CustomLicenseUpdaterRequest;

export const changeCustomLicenseRequestSchema = z.object({
  customLicense: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .min(1, '認定資格（その他）[自由記述]を入力して下さい。')
    // eslint-disable-next-line no-magic-numbers
    .max(100, '認定資格（その他）[自由記述]を100文字以内で入力して下さい。'),
});

export const isChangeCustomLicenseRequest = (
  value: unknown,
): value is ChangeCustomLicenseRequest =>
  validation(changeCustomLicenseRequestSchema, value).isValidate;

export type ChangeCustomLicense = (
  request: ChangeCustomLicenseRequest,
) => Promise<void>;
