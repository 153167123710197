// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-await
import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';

import { fetchLoggedInUser } from '../../api/client/fetch/user';
import { unknownPictureUrl } from '../../constants/url';
import { changeActivityAreaRequestSchema } from '../../domain/activityArea';
import {
  validateChangeAddressRequest,
  validatePostalCodeRequest,
} from '../../domain/address';
import { changeAffiliationRequestSchema } from '../../domain/affiliation';
import { changeCustomLicenseRequestSchema } from '../../domain/customLicense';
import { changeEmploymentRequestSchema } from '../../domain/employment';
import { changeLicenseRequestSchema } from '../../domain/license';
import { changeNurseLicenseNumberSchema } from '../../domain/licenseNumber';
import { changePhoneNumberSchema } from '../../domain/phoneNumber';
import { validateQualificationsRequest } from '../../domain/qualification';
import { changeScholarshipRequestSchema } from '../../domain/scholarship';
import { changeSchoolRequestSchema } from '../../domain/school';
import {
  changeProfileRequestSchema,
  isChangePreferredUsernameRequest,
  isChangeProfileRequest,
  validateChangeNameRequest,
} from '../../domain/user';

import type {
  AddressValidator,
  NameValidator,
  PhoneNumberValidator,
  PostalCodeValidator,
  PreferredUsernameValidator,
  ProfileValidator,
  ActivityAreaIdsValidator,
  LicenseIdsValidator,
  AffiliationIdsValidator,
  CustomLicenseValidator,
  NurseLicenseNumberValidator,
  SchoolValidator,
  ScholarshipValidator,
} from '@nurse-senka/nurse-senka-web-ui';

// eslint-disable-next-line require-await
const nameValidator: NameValidator = async (value: unknown) =>
  validateChangeNameRequest(value);

const preferredUsernameValidator: PreferredUsernameValidator = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: unknown,
  // eslint-disable-next-line require-await
) => {
  if (isChangePreferredUsernameRequest(value)) {
    if (value.preferredUsername === '未設定') {
      return {
        isValidate: false,
        invalidParams: [
          {
            name: 'preferredUsername',
            reason: '未設定というニックネームは設定出来ません。',
          },
        ],
      };
    }

    return {
      isValidate: true,
    };
  }

  return {
    isValidate: false,
    invalidParams: [
      {
        name: 'preferredUsername',
        reason: 'ニックネームに記号なしで1文字以上50文字以上で入力して下さい。',
      },
    ],
  };
};

const phoneNumberValidator: PhoneNumberValidator = async (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value: unknown,
  // eslint-disable-next-line require-await
) => validation(changePhoneNumberSchema, value);

const postalCodeValidator: PostalCodeValidator = async (
  value: unknown,
  // eslint-disable-next-line require-await
) => validatePostalCodeRequest(value);

const addressValidator: AddressValidator = async (
  value: unknown,
  // eslint-disable-next-line require-await
) => validateChangeAddressRequest(value);

// eslint-disable-next-line @typescript-eslint/no-unused-vars, require-await
const profileValidator: ProfileValidator = async (value: unknown) => {
  if (isChangeProfileRequest(value)) {
    if (!value.profileImage && !value.profileImageExtension) {
      const loggedInUser = await fetchLoggedInUser();
      if (loggedInUser.pictureUrl === unknownPictureUrl()) {
        return {
          isValidate: false,
          invalidParams: [
            {
              name: 'selfIntroduction',
              reason:
                'プロフィール画像が未登録なので自己紹介とプロフィール画像は同時に設定が必要です。',
            },
          ],
        };
      }
    }
  }

  return validation(changeProfileRequestSchema, value);
};

// eslint-disable-next-line require-await
const employmentIdValidator = async (value: unknown) =>
  validation(changeEmploymentRequestSchema, value);

const activityAreaIdsValidator: ActivityAreaIdsValidator = async (
  value: unknown,
  // eslint-disable-next-line require-await
) => validation(changeActivityAreaRequestSchema, value);

// eslint-disable-next-line require-await
const licenseIdsValidator: LicenseIdsValidator = async (value: unknown) =>
  validation(changeLicenseRequestSchema, value);

const affiliationIdsValidator: AffiliationIdsValidator = async (
  value: unknown,
  // eslint-disable-next-line require-await
) => validation(changeAffiliationRequestSchema, value);

// eslint-disable-next-line require-await
const customLicenseValidator: CustomLicenseValidator = async (value: unknown) =>
  validation(changeCustomLicenseRequestSchema, value);

const nurseLicenseNumberValidator: NurseLicenseNumberValidator = async (
  value: unknown,
  // eslint-disable-next-line require-await
) => validation(changeNurseLicenseNumberSchema, value);

// eslint-disable-next-line require-await
const schoolValidator: SchoolValidator = async (value: unknown) =>
  validation(changeSchoolRequestSchema, value);

// eslint-disable-next-line require-await
const scholarshipValidator: ScholarshipValidator = async (value: unknown) =>
  validation(changeScholarshipRequestSchema, value);

export const validators = {
  basicInfo: {
    name: nameValidator,
    preferredUsername: preferredUsernameValidator,
    phoneNumber: phoneNumberValidator,
    postalCode: postalCodeValidator,
    address: addressValidator,
    profile: profileValidator,
  },
  qualificationsAndWork: {
    qualificationCodeList: validateQualificationsRequest,
    employmentId: employmentIdValidator,
    affiliationIds: affiliationIdsValidator,
    activityAreaIds: activityAreaIdsValidator,
    licenseIds: licenseIdsValidator,
    customLicense: customLicenseValidator,
    nurseLicenseNumber: nurseLicenseNumberValidator,
  },
  academicBackground: {
    school: schoolValidator,
    scholarship: scholarshipValidator,
  },
};
