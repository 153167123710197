import {
  AccountTemplate,
  MailMagazineSettingContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { useState, type FC, ChangeEventHandler, FormEventHandler } from 'react';

import {
  toDoNotReceiveJobsNotification,
  toDoNotReceiveNewsNotification,
  toReceiveJobsNotification,
  toReceiveNewsNotification,
} from '../../api/client/fetch/notification';
import { AppHeader } from '../../components/AppHeader';
import { appUrl, portalUrl } from '../../constants/url';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'Myページ',
    url: appUrl.top,
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: 'メルマガの受信設定',
    url: appUrl.mailMagazineSetting,
  } as const,
];

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser: LoggedInUser;
  shouldReceiveNewsNotificationResult: boolean;
  shouldReceiveJobsNotificationResult: boolean;
};

// eslint-disable-next-line max-lines-per-function
export const MailMagazineSettingsTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  loggedInUser,
  shouldReceiveNewsNotificationResult,
  shouldReceiveJobsNotificationResult,
}) => {
  const [newsNotification, setNewsNotification] = useState<
    'receive' | 'notReceive'
  >(shouldReceiveNewsNotificationResult ? 'receive' : 'notReceive');
  const [jobsNotification, setJobsNotification] = useState<
    'receive' | 'notReceive'
  >(shouldReceiveJobsNotificationResult ? 'receive' : 'notReceive');
  const [loading, setLoading] = useState<boolean>(false);
  const [disableSubmitButton, setDisableSubmitButton] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const changeNewsNotification: ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    if (
      event.target.value === 'receive' ||
      event.target.value === 'notReceive'
    ) {
      setNewsNotification(event.target.value);
    }
  };

  const changeJobsNotification: ChangeEventHandler<HTMLSelectElement> = (
    event,
  ) => {
    if (
      event.target.value === 'receive' ||
      event.target.value === 'notReceive'
    ) {
      setJobsNotification(event.target.value);
    }
  };

  // eslint-disable-next-line require-await
  const submit: FormEventHandler<HTMLFormElement> = async (event) => {
    event.preventDefault();
    try {
      const changeReceiveNewsNotificationFunc =
        newsNotification === 'notReceive'
          ? toDoNotReceiveNewsNotification
          : toReceiveNewsNotification;

      const changeReceiveJobsNotificationFunc =
        jobsNotification === 'notReceive'
          ? toDoNotReceiveJobsNotification
          : toReceiveJobsNotification;

      setLoading(true);
      setDisableSubmitButton(true);

      await changeReceiveNewsNotificationFunc();

      await changeReceiveJobsNotificationFunc();

      setLoading(false);
      setDisableSubmitButton(false);
    } catch (error) {
      setLoading(false);
      setDisableSubmitButton(false);
      setErrorMessage(
        '予期せぬエラーが発生しました。申し訳ありませんがしばらく時間が経ってからお試しください。',
      );
    }
  };

  const eventHandlers = {
    changeNewsNotification,
    changeJobsNotification,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        pageHeader={{ title: 'メルマガの受信設定' }}
        appEnv={appEnv}
        breadcrumbElements={breadcrumbElements}
        loading={loading}
        isLoggedIn={true}
        loggedInUser={loggedInUser}
      >
        <MailMagazineSettingContents
          appEnv={appEnv}
          loggedInUser={loggedInUser}
          disableSubmitButton={disableSubmitButton}
          newsNotificationDefaultValue={
            shouldReceiveNewsNotificationResult ? 'receive' : 'notReceive'
          }
          jobsNotificationDefaultValue={
            shouldReceiveJobsNotificationResult ? 'receive' : 'notReceive'
          }
          errorMessage={errorMessage}
          eventHandlers={eventHandlers}
        />
      </AccountTemplate>
    </>
  );
};
