import {
  format as dateFormat,
  isWithinInterval,
  getUnixTime,
  addDays,
  isBefore,
  differenceInCalendarDays,
  formatDistanceStrict,
} from 'date-fns';
import { ja } from 'date-fns/locale';

export const formatDate = (date: Date, format = 'yyyy/MM/dd') =>
  dateFormat(date, format);

export const formatDistanceToNowDateTime = (nowDate: Date, targetDate: Date) =>
  formatDistanceStrict(targetDate, nowDate, { locale: ja });

// TargetDate が指定した期間内かどうかを検証する
export const datePeriodInContains = (
  targetDate: Date,
  startDate: Date,
  endDate: Date,
) => isWithinInterval(targetDate, { start: startDate, end: endDate });

// 有効な日付かどうかを検証する
export const isValidDate = (dateString: string): boolean => {
  const dateObject = new Date(dateString);
  try {
    const formattedDate = formatDate(dateObject, 'yyyy-MM-dd');

    return dateString === formattedDate;
  } catch (error) {
    return false;
  }
};

export const formatUnixTime = (targetDate: Date): number =>
  getUnixTime(targetDate);

export const addDay = (targetDate: Date, addAmount: number): Date =>
  addDays(targetDate, addAmount);

// targetDateが有効期限内であるか確認する
export const isInExpirationDate = (nowDate: Date, targetDate: Date) =>
  isBefore(nowDate, targetDate);

export const difference = (nowDate: Date, targetDate: Date) =>
  differenceInCalendarDays(nowDate, targetDate);
