import Script from 'next/script';

export const GoogleAdManager = () => (
  <>
    <Script
      id="gpt"
      src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"
      strategy="afterInteractive"
    />
    <Script
      id="ad-manager"
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `var googletag = googletag || {}; googletag.cmd = googletag.cmd || [];`,
      }}
    />
  </>
);
