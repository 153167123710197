/* eslint-disable no-magic-numbers */
import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ToDoNotReceiveJobsNotificationError } from '../../../domain/errors/ToDoNotReceiveJobsNotificationError';
import { ToDoNotReceiveNewsNotificationError } from '../../../domain/errors/ToDoNotReceiveNewsNotificationError';
import { ToReceiveJobsNotificationError } from '../../../domain/errors/ToReceiveJobsNotificationError';
import { ToReceiveNewsNotificationError } from '../../../domain/errors/ToReceiveNewsNotificationError';
import { ChangeReceiveNotificationOnClient } from '../../../domain/notification';

// 新着通知の受信設定を登録する
export const toReceiveNewsNotification: ChangeReceiveNotificationOnClient =
  async () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(apiPath.newsNotification, options);
    if (response.status !== httpStatusCode.noContent) {
      throw new ToReceiveNewsNotificationError();
    }
  };

// 新着通知の受信設定を削除する
export const toDoNotReceiveNewsNotification: ChangeReceiveNotificationOnClient =
  async () => {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(apiPath.newsNotification, options);
    if (response.status !== httpStatusCode.noContent) {
      throw new ToDoNotReceiveNewsNotificationError();
    }
  };

// 就職・転職通知の受信設定を登録する
export const toReceiveJobsNotification: ChangeReceiveNotificationOnClient =
  async () => {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(apiPath.jobsNotification, options);
    if (response.status !== httpStatusCode.noContent) {
      throw new ToReceiveJobsNotificationError();
    }
  };

// 就職・転職通知の受信設定を削除する
export const toDoNotReceiveJobsNotification: ChangeReceiveNotificationOnClient =
  async () => {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(apiPath.jobsNotification, options);
    if (response.status !== httpStatusCode.noContent) {
      throw new ToDoNotReceiveJobsNotificationError();
    }
  };
