/* eslint-disable no-magic-numbers */
import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import type { AffiliationIdsUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export const affiliationIds = [
  5, 4, 3, 12, 24, 6, 35, 32, 23, 25, 30, 18, 17, 11, 40, 7, 37, 15, 8, 38, 13,
  22, 10, 42, 9, 41, 26, 14, 20, 27, 34, 21, 16, 36, 39, 19, 33, 28, 31, 29, 1,
  2, 43,
] as const;

export type AffiliationId = (typeof affiliationIds)[number];

export const affiliationLabels = [
  '消化器科',
  '呼吸器科',
  '循環器科',
  '脳神経外科',
  '神経内科・脳神経内科',
  '整形外科',
  '形成外科',
  '美容外科',
  '透析・腎臓内科',
  '代謝・内分泌科',
  '糖尿病内科',
  'リウマチ・膠原病科',
  '血液内科',
  '泌尿器科',
  '肛門科',
  '精神科',
  '心療内科',
  'リハビリテーション科',
  '小児科',
  '産科・NICU',
  '婦人科',
  '在宅（訪問看護）',
  '救急救命',
  'ICU・CCU・HCU',
  '手術室',
  '眼科',
  '耳鼻・咽喉科',
  '皮膚科',
  'アレルギー科',
  '歯・口腔外科',
  '療養病床',
  '地域包括ケア病棟',
  '緩和ケア科',
  '放射線科',
  '麻酔科',
  '地域医療連携・退院支援',
  '管理部門',
  '検診センター',
  '化学療法室',
  '総合診療科',
  '一般内科',
  '一般外科',
  'その他',
];

export type AffiliationLabel = (typeof affiliationLabels)[number];

export type Affiliation = {
  id: AffiliationId;
  label: AffiliationLabel;
};

export const affiliations: Affiliation[] = affiliationIds.map(
  (value, index) => ({
    id: value,
    label: affiliationLabels[index],
  }),
);

export const changeAffiliationRequestSchema = z.object({
  affiliationIds: z.number().array().nonempty('所属科を正しく設定して下さい。'),
});

export type ChangeAffiliationRequest = AffiliationIdsUpdaterRequest;

export const isChangeAffiliationRequest = (
  value: unknown,
): value is ChangeAffiliationRequest =>
  validation(changeAffiliationRequestSchema, value).isValidate;

export type ChangeAffiliation = (
  request: ChangeAffiliationRequest,
) => Promise<void>;
