/* eslint-disable no-magic-numbers */

const unitId = 179873893;

const unitList = [
  `/${unitId}/NSC_SP_GlobalFooter`,
  /*
   * `/${unitId}/NSC_SP_GlobalHeader`,
   * `/${unitId}/NSC_SP_Press`,
   */
  `/${unitId}/NSC_PC_SMS_Sidebar`,
  `/${unitId}/NSC_PC_Square`,
  // `/${unitId}/NSC_PC_Press`,
  `/${unitId}/NSC_PC_Square_Bottom`,
] as const;

export type Unit = (typeof unitList)[number];

const slotIdList = [
  'div-gpt-ad-1426472053621-0',
  /*
   * 'div-gpt-ad-1426472053621-1',
   * 'div-gpt-ad-1426472053621-2',
   */
  'div-gpt-ad-1426470100054-6',
  'div-gpt-ad-1426470100054-7',
  // 'div-gpt-ad-1426470100054-8',
  'div-gpt-ad-1426470100054-9',
] as const;

export type SlotId = (typeof slotIdList)[number];

const sizeList = [
  [
    [300, 250],
    [320, 250],
  ],
  /*
   * [320, 50],
   * [320, 56],
   */
  [320, 60],
  [
    [300, 250],
    [320, 250],
  ],
  // [605, 56],
  [
    [300, 250],
    [320, 250],
  ],
] as const;

type SingleSize = [number, number] | 'fluid' | ['fluid'];

type MultiSize = SingleSize[];

type Size = MultiSize | SingleSize;

export type GoogleAdManagerSlot = {
  unit: `/${typeof unitId}${string}`;
  size: Size;
  id: SlotId;
};

export const googleAdManagerSlotList: GoogleAdManagerSlot[] = unitList.map(
  (value, index) => ({
    unit: value,
    size: sizeList[index] as Size,
    id: slotIdList[index],
  }),
);

export const defineSlots = () => {
  const { googletag } = window;

  googletag.cmd.push(() => {
    for (const googleAdManagerSlot of googleAdManagerSlotList) {
      googletag
        .defineSlot(
          googleAdManagerSlot.unit,
          googleAdManagerSlot.size,
          googleAdManagerSlot.id,
        )
        ?.addService(googletag.pubads());
    }

    googletag.enableServices();

    for (const slotId of slotIdList) {
      googletag.display(slotId);
    }
  });
};

export const removeSlots = () => {
  const { googletag } = window;
  googletag.cmd.push(() => {
    googletag.destroySlots();
  });
};
