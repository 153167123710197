import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeLicenseError } from '../../../domain/errors/ChangeLicenseError';

import type { ChangeLicense } from '../../../domain/license';

export const changeLicense: ChangeLicense = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeLicense, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeLicenseError(
      `${response.statusText} src/api/client/fetch/license.ts failed to changeLicense`,
    );
  }
};
