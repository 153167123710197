import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeNameError } from '../../../domain/errors/ChangeNameError';

import type { ChangeName } from '../../../domain/user';

export const changeName: ChangeName = async (request) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeName, options);
  if (response.status !== httpStatusCode.ok) {
    throw new ChangeNameError();
  }
};
