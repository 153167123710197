import Head from 'next/head';

import type { MetaTag } from '../../constants/metaTag';
import type { FC } from 'react';

type Props = {
  metaTag: MetaTag;
  noIndex: boolean;
};

export const AppHeader: FC<Props> = ({ metaTag, noIndex }) => (
  <Head>
    <title>{metaTag.title}</title>
    <meta charSet="utf-8" />
    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
    {noIndex ? <meta name="robots" content="noindex , nofollow" /> : ''}
    <meta property="og:title" content={metaTag.title} />
    <meta property="og:description" content={metaTag.description} />
    <meta property="og:type" content="website" />
    <meta property="og:image" content={metaTag.ogpImgUrl} />
    <meta property="og:url" content={metaTag.ogpTargetUrl} />
    <meta property="og:site_name" content={metaTag.appName} />
    <link rel="icon" href="/favicon.ico" />
  </Head>
);
