import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { ChangeNurseLicenseNumberError } from '../../../domain/errors/ChangeNurseLicenseNumberError';
import { ChangeNurseLicenseNumber } from '../../../domain/licenseNumber';

export const changeNurseLicenseNumber: ChangeNurseLicenseNumber = async (
  request,
) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.changeNurseLicenseNumber, options);

  if (response.status !== httpStatusCode.ok) {
    throw new ChangeNurseLicenseNumberError(
      `${response.statusText} src/api/client/fetch/nurseLicenseNumber.ts failed to changeNurseLicenseNumber`,
    );
  }
};
