import {
  AccountTemplate,
  PasswordResetApplyContents,
  CompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { useState, type FC, ChangeEvent, FormEvent } from 'react';
import { v4 } from 'uuid';

import { passwordResetApply } from '../../api/client/fetch/passwordReset';
import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { appUrl, portalUrl } from '../../constants/url';
import { createAuthorizationRequest } from '../../domain/auth';
import { isPasswordResetApplyRequest } from '../../domain/passwordReset';
import { isFailureResult } from '../../domain/result';
import { Email, isEmail } from '../../domain/user';

import type { MetaTag } from '../../constants/metaTag';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';

const authorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

const pageTitle = 'パスワードの再設定';

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: pageTitle,
    url: appUrl.passwordResetApply,
  } as const,
];

const backToLinks = {
  text: 'パスワード再設定用URLを記載したメールを送信しました。メールをご確認下さい。',
  buttonText: 'ナース専科TOPへ戻る',
  url: portalUrl(),
};

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
};

// eslint-disable-next-line max-lines-per-function
export const PasswordResetApplyTemplate: FC<Props> = ({ appEnv, metaTag }) => {
  const [email, setEmail] = useState<Email>();

  const [applied, setApplied] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string>('');

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;

    if (isEmail(inputEmail)) {
      setEmail(inputEmail);
      setDisableSubmitButton(false);
    }
  };

  // eslint-disable-next-line require-await
  const submit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      const request = {
        email,
      };

      if (isPasswordResetApplyRequest(request)) {
        setDisableSubmitButton(true);

        const passwordResetApplyResult = await passwordResetApply(request);
        if (isFailureResult(passwordResetApplyResult)) {
          setApplied(false);
          setErrorMessage(
            'メールアドレスが無効です。お手数ですがログイン時にご利用されているメールアドレスをご入力下さい。',
          );

          return;
        }

        setApplied(true);
        setErrorMessage('');
      } else {
        setErrorMessage('メールアドレスとして正しい値を入力して下さい。');
      }
    } catch (error) {
      setApplied(false);
      setErrorMessage(
        '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
      );
    }
  };

  const eventHandlers = {
    changeEmail,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      {applied ? (
        <AccountTemplate
          appEnv={appEnv}
          pageHeader={{ title: pageTitle }}
          breadcrumbElements={breadcrumbElements}
          authorizationRequest={authorizationRequest}
          isLoggedIn={false}
          loading={false}
        >
          <CompleteContents backToLinks={backToLinks} />
        </AccountTemplate>
      ) : (
        <AccountTemplate
          appEnv={appEnv}
          pageHeader={{ title: pageTitle }}
          breadcrumbElements={breadcrumbElements}
          authorizationRequest={authorizationRequest}
          isLoggedIn={false}
          loading={false}
        >
          <PasswordResetApplyContents
            disableSubmitButton={disableSubmitButton}
            eventHandlers={eventHandlers}
            errorMessage={errorMessage}
          />
        </AccountTemplate>
      )}
    </>
  );
};
