import {
  assertNever,
  httpStatusCode,
} from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { AccountNotFoundError } from '../../../domain/errors/AccountNotFoundError';
import { InvalidPasswordResetTokenError } from '../../../domain/errors/InvalidPasswordResetTokenError';
import { PasswordResetApplyError } from '../../../domain/errors/PasswordResetApplyError';
import { PasswordResetCompleteError } from '../../../domain/errors/PasswordResetCompleteError';
import { PasswordResetCompleteSamePasswordError } from '../../../domain/errors/PasswordResetCompleteSamePasswordError';
import { PasswordResetTokenExpiredError } from '../../../domain/errors/PasswordResetTokenExpiredError';
import { PasswordResetTokenNotFoundError } from '../../../domain/errors/PasswordResetTokenNotFoundError';
import {
  createFailureResult,
  createSuccessResult,
} from '../../../domain/result';
import { ResponseErrorBody } from '../../../pages/api/accounts/password/reset/complete';

import type {
  PasswordResetApplyOnClient,
  PasswordResetApplyResponse,
  PasswordResetCompleteOnClient,
} from '../../../domain/passwordReset';

export const passwordResetApply: PasswordResetApplyOnClient = async (
  request,
) => {
  const options = {
    method: 'POST',
    body: JSON.stringify({ email: request.email }),
  };

  const response = await fetch(apiPath.passwordResetApply, options);

  if (response.status !== httpStatusCode.created) {
    if (response.status === httpStatusCode.notFound) {
      return createFailureResult(new AccountNotFoundError());
    }

    throw new PasswordResetApplyError(
      `${response.statusText} src/api/client/fetch/passwordReset.ts failed to passwordResetApply`,
    );
  }

  const responseBody = (await response.json()) as PasswordResetApplyResponse;

  return createSuccessResult(responseBody);
};

export const passwordResetComplete: PasswordResetCompleteOnClient = async (
  request,
) => {
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      passwordResetToken: request.passwordResetToken,
      password: request.password,
    }),
  };

  const response = await fetch(apiPath.passwordResetComplete, options);

  if (response.status !== httpStatusCode.ok) {
    const responseBody = (await response.json()) as ResponseErrorBody;

    if (
      response.status === httpStatusCode.notFound ||
      response.status === httpStatusCode.badRequest
    ) {
      switch (responseBody.message) {
        case 'InvalidPasswordResetTokenError':
          return createFailureResult(new InvalidPasswordResetTokenError());
        case 'PasswordResetTokenNotFoundError':
          return createFailureResult(new PasswordResetTokenNotFoundError());
        case 'PasswordResetTokenExpiredError':
          return createFailureResult(new PasswordResetTokenExpiredError());
        case 'PasswordResetCompleteSamePasswordError':
          return createFailureResult(
            new PasswordResetCompleteSamePasswordError(),
          );
        case 'unprocessable entity':
          throw new PasswordResetCompleteError(
            `${response.statusText} src/api/client/fetch/passwordReset.ts failed to passwordResetComplete`,
          );
        default:
          assertNever(responseBody.message);
      }
    }

    throw new PasswordResetCompleteError(
      `${response.statusText} src/api/client/fetch/passwordReset.ts failed to passwordResetComplete`,
    );
  }

  return createSuccessResult({
    passwordResetToken: request.passwordResetToken,
  });
};
