import {
  AccountTemplate,
  AuthorizationRequest,
  LoginContents,
} from '@nurse-senka/nurse-senka-web-ui';
import { setCookie } from 'nookies';
import { useState, type FC, ChangeEvent, FormEvent } from 'react';
import { v4 } from 'uuid';

import { login } from '../../api/client/fetch/login';
import { AppHeader } from '../../components/AppHeader';
import { oidcClientId } from '../../constants/env';
import { apiPath, appBaseUrl, appUrl, portalUrl } from '../../constants/url';
import {
  createAuthorizationRequest,
  createAuthorizationUrl,
} from '../../domain/auth';
import { isLoginRequest, validateLoginRequest } from '../../domain/login';
import { isSuccessResult } from '../../domain/result';
import { isEmail, type Email } from '../../domain/user';
import { addDay } from '../../utils/date';
import { sendRecommendedEvent } from '../../utils/gtm';

import type { MetaTag } from '../../constants/metaTag';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';

const defaultAuthorizationRequest = createAuthorizationRequest({
  clientId: oidcClientId(),
  redirectUri: appUrl.top,
  generateUniqueId: v4,
});

const authorizationParams = {
  client_id: String(defaultAuthorizationRequest.clientId),
  redirect_uri: defaultAuthorizationRequest.redirectUri,
  state: defaultAuthorizationRequest.state,
  nonce: defaultAuthorizationRequest.nonce,
};

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: 'ログイン',
    url: createAuthorizationUrl(authorizationParams),
  } as const,
];

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  queryParams?: { [key: string]: unknown };
  redirectUriAfterRegistration?: string;
  initAuthorizationRequest?: AuthorizationRequest;
};

// eslint-disable-next-line max-lines-per-function
export const LoginTemplate: FC<Props> = ({
  appEnv,
  metaTag,
  queryParams,
  redirectUriAfterRegistration,
  initAuthorizationRequest,
}) => {
  const [email, setEmail] = useState<Email>();
  const [password, setPassword] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const authorizationRequest =
    initAuthorizationRequest || defaultAuthorizationRequest;

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    const inputEmail = event.target.value;

    if (isEmail(inputEmail)) {
      setEmail(inputEmail);
    }
  };

  const changePassword = (event: ChangeEvent<HTMLInputElement>) => {
    const inputPassword = event.target.value;

    if (inputPassword) {
      setPassword(inputPassword);
    }
  };

  // eslint-disable-next-line require-await
  const submit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const request = {
      email,
      password,
    };

    if (validateLoginRequest(request)) {
      if (isLoginRequest(request)) {
        setLoading(true);
        try {
          const loginResult = await login(request);

          // Cookieが先に消えてしまう事を防ぐため、トークンの有効期限より少し長めの値を設定しておく
          const loginSessionTokenCookieExpires = 15;

          if (isSuccessResult(loginResult)) {
            setCookie(
              null,
              'login_session_token',
              loginResult.value.loginSessionToken,
              {
                domain:
                  appEnv === 'production' ? 'nurse-senka.jp' : 'smsc-nsc.com',
                secure: appEnv === 'production',
                httpOnly: false,
                expires: addDay(new Date(), loginSessionTokenCookieExpires),
                path: '/',
              },
            );

            sendRecommendedEvent('login');

            location.href = queryParams
              ? createAuthorizationUrl(queryParams)
              : `${appBaseUrl()}${apiPath.authorization}`;
          } else {
            setLoading(false);
            setErrorMessage('メールアドレス、またはパスワードが異なります。');
          }
        } catch (error) {
          setLoading(false);
          setErrorMessage(
            '予期せぬエラーが発生しました。お手数ですがしばらく時間が経ってからお試し下さい。',
          );
        }
      }
    }
  };

  const eventHandlers = {
    changeEmail,
    changePassword,
    submit,
  };

  return (
    <>
      <AppHeader metaTag={metaTag} noIndex={false} />
      <AccountTemplate
        appEnv={appEnv}
        pageHeader={{ title: 'ログイン' }}
        breadcrumbElements={breadcrumbElements}
        isLoggedIn={false}
        loading={loading}
        redirectUriAfterRegistration={redirectUriAfterRegistration}
        authorizationRequest={authorizationRequest}
      >
        <LoginContents
          appEnv={appEnv}
          eventHandlers={eventHandlers}
          disableSubmitButton={false}
          errorMessage={errorMessage}
          redirectUriAfterRegistration={redirectUriAfterRegistration}
        />
      </AccountTemplate>
    </>
  );
};
