import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import { ChangeEmailCompleteError } from './errors/ChangeEmailCompleteError';
import { FindEmailVerifyTokenNotFoundError } from './errors/FindEmailVerifyTokenNotFoundError';
import UsedEmailAlreadyError from './errors/UsedEmailAlreadyError';

import type { AccessTokenValue } from './auth';
import type { Result } from './result';
import type { Email, LoggedInUser, Sub } from './user';

const changeEmailApplySchema = z.object({
  newEmail: z.string().email(),
});

type ChangeEmailApplyRequest = {
  loggedInUser: LoggedInUser;
  newEmail: Email;
};

export type EmailVerifyToken = string;

export type ChangeEmailApplyResponse = {
  emailVerifyToken: EmailVerifyToken;
};

export type ChangeEmailApply = (
  accessToken: AccessTokenValue,
  request: ChangeEmailApplyRequest,
) => Promise<Result<ChangeEmailApplyResponse, UsedEmailAlreadyError>>;

type ChangeEmailApplyOnClientRequest = {
  newEmail: Email;
};

const validateChangeEmailApplyOnClientRequest = (request: unknown) =>
  validation(changeEmailApplySchema, request);

export const isChangeEmailApplyOnClientRequest = (
  value: unknown,
): value is ChangeEmailApplyOnClientRequest =>
  validateChangeEmailApplyOnClientRequest(value).isValidate;

export type ChangeEmailApplyOnClient = (
  request: ChangeEmailApplyOnClientRequest,
) => Promise<Result<ChangeEmailApplyResponse, UsedEmailAlreadyError>>;

type FindEmailVerifyTokenResponse = {
  emailVerifyToken: EmailVerifyToken;
  sub: Sub;
};

export type FindEmailVerifyToken = (
  accessToken: AccessTokenValue,
  emailVerifyToken: EmailVerifyToken,
) => Promise<
  Result<FindEmailVerifyTokenResponse, FindEmailVerifyTokenNotFoundError>
>;

type ChangeEmailCompleteRequest = FindEmailVerifyTokenResponse;

type ChangeEmailCompleteResponse = FindEmailVerifyTokenResponse;

export type ChangeEmailComplete = (
  accessToken: AccessTokenValue,
  request: ChangeEmailCompleteRequest,
) => Promise<Result<ChangeEmailCompleteResponse, ChangeEmailCompleteError>>;
