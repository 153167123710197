import { httpStatusCode } from '@nurse-senka/nurse-senka-frontend-sdk';

import { apiPath } from '../../../constants/url';
import { AuthenticationFailedError } from '../../../domain/errors/AuthenticationFailedError';
import { UnexpectedLoginError } from '../../../domain/errors/UnexpectedLoginError';
import {
  createFailureResult,
  createSuccessResult,
} from '../../../domain/result';

import type { Login, LoginResponse } from '../../../domain/login';

export const login: Login = async (request) => {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(request),
  };

  const response = await fetch(apiPath.login, options);

  if (response.status === httpStatusCode.unauthorized) {
    return createFailureResult<AuthenticationFailedError>(
      new AuthenticationFailedError(),
    );
  }

  if (response.status !== httpStatusCode.created) {
    throw new UnexpectedLoginError(
      `${response.statusText} src/api/client/fetch/login.ts failed to login`,
    );
  }

  const responseBody = (await response.json()) as LoginResponse;

  return createSuccessResult(responseBody);
};
