import {
  AccountTemplate,
  CompleteContents,
} from '@nurse-senka/nurse-senka-web-ui';

import { AppHeader } from '../../components/AppHeader';
import { appUrl, portalUrl } from '../../constants/url';

import type { MetaTag } from '../../constants/metaTag';
import type { LoggedInUser } from '../../domain/user';
import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { FC } from 'react';

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  loggedInUser?: LoggedInUser;
};

const breadcrumbElements = [
  {
    type: 'listItem',
    order: 1,
    text: 'TOP',
    url: portalUrl(),
  } as const,
  {
    type: 'listItem',
    order: 2,
    text: '会員登録',
    url: appUrl.registrations,
  } as const,
  {
    type: 'listItem',
    order: 3,
    text: '会員登録完了',
    url: appUrl.registrationsComplete,
  } as const,
];

export const RegistrationCompleteTemplate: FC<Props> = ({
  appEnv,
  metaTag,
}) => (
  <>
    <AppHeader metaTag={metaTag} noIndex={false} />
    <AccountTemplate
      pageHeader={{ title: '会員登録完了' }}
      appEnv={appEnv}
      breadcrumbElements={breadcrumbElements}
      isLoggedIn={true}
      loading={false}
    >
      <CompleteContents
        backToLinks={{
          text: '会員登録が完了しました。引き続きナース専科をお楽しみ下さい。',
          buttonText: 'ナース専科トップページへ',
          url: portalUrl(),
        }}
      />
    </AccountTemplate>
  </>
);
