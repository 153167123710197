import {
  validation,
  isPrefectureId,
  PrefectureId,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

export type PostalCode = string;

export type PostalCodeRequest = {
  postalCode: PostalCode;
};

export const postalCodeRegExp = /^[0-9]{3}[0-9]{4}$/u;

export const postalCodeValidationErrorMessage =
  '郵便番号はハイフンなしの数字7桁で入力して下さい。';

const postalCodeRequestSchema = z.object({
  postalCode: z
    .string()
    .regex(postalCodeRegExp, postalCodeValidationErrorMessage),
});

export const validatePostalCodeRequest = (value: unknown) =>
  validation(postalCodeRequestSchema, value);

export const isPostalCodeRequest = (
  value: unknown,
): value is PostalCodeRequest => validatePostalCodeRequest(value).isValidate;

const changeAddressSchema = z.object({
  postalCode: z
    .string()
    .regex(postalCodeRegExp, postalCodeValidationErrorMessage),
  prefectureId: z
    .number()
    .refine(
      (value) => isPrefectureId(value),
      '都道府県を正しく選択して下さい。',
    ),
  locality: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .min(1, '市区町村を入力して下さい。')
    // eslint-disable-next-line no-magic-numbers
    .max(100, '市区町村は100文字以内で入力して下さい。'),
  streetAddress: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .min(1, '番地を入力して下さい。')
    // eslint-disable-next-line no-magic-numbers
    .max(100, '番地は100文字以内で入力して下さい。'),
  building: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .max(100, '建物名は100文字以内で入力して下さい。')
    .optional(),
});

export type ChangeAddressRequest = {
  postalCode: PostalCode;
  prefectureId: PrefectureId;
  locality: string;
  streetAddress: string;
  building?: string;
};

export const validateChangeAddressRequest = (value: unknown) =>
  validation(changeAddressSchema, value);

export const isChangeAddressRequest = (
  value: unknown,
): value is ChangeAddressRequest =>
  validateChangeAddressRequest(value).isValidate;

export type ChangeAddress = (request: ChangeAddressRequest) => Promise<void>;
