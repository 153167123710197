import { validation } from '@nurse-senka/nurse-senka-frontend-sdk';
import { z } from 'zod';

import type { NurseLicenseNumberUpdaterRequest } from '@nurse-senka/nurse-senka-web-ui';

export type ChangeNurseLicenseNumberRequest = NurseLicenseNumberUpdaterRequest;

export const changeNurseLicenseNumberSchema = z.object({
  nurseLicenseNumber: z
    .string()
    // eslint-disable-next-line no-magic-numbers
    .min(6, '看護師免許番号は6〜7桁の数字で入力して下さい。')
    // eslint-disable-next-line no-magic-numbers
    .max(7, '看護師免許番号は6〜7桁の数字で入力して下さい。'),
});

export const isChangeNurseLicenseNumberRequest = (
  value: unknown,
): value is ChangeNurseLicenseNumberRequest =>
  validation(changeNurseLicenseNumberSchema, value).isValidate;

export type ChangeNurseLicenseNumber = (
  request: ChangeNurseLicenseNumberRequest,
) => Promise<void>;
