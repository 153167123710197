import {
  AcademicBackgroundUpdaters,
  BasicInfoUpdaters,
  QualificationsAndWorkUpdaters,
} from '@nurse-senka/nurse-senka-web-ui';

import { changeActivityArea } from '../../api/client/fetch/activityArea';
import { changeAddress } from '../../api/client/fetch/address';
import { changeAffiliation } from '../../api/client/fetch/affiliation';
import { changeBirthdate } from '../../api/client/fetch/birthdate';
import { changeCustomLicense } from '../../api/client/fetch/customLicense';
import { changeEmployment } from '../../api/client/fetch/employment';
import { changeFamilyStructure } from '../../api/client/fetch/familyStructure';
import { changeLicense } from '../../api/client/fetch/license';
import { changeName } from '../../api/client/fetch/name';
import { changeNurseLicenseNumber } from '../../api/client/fetch/nurseLicenseNumber';
import { changePhoneNumber } from '../../api/client/fetch/phoneNumber';
import { changePreferredUsername } from '../../api/client/fetch/preferredUsername';
import { changeProfile } from '../../api/client/fetch/profile';
import { changeQualifications } from '../../api/client/fetch/qualification';
import { changeScholarship } from '../../api/client/fetch/scholarship';
import { changeSchool } from '../../api/client/fetch/school';
import { changeGender } from '../../api/client/gender';

const basicInfoUpdaters: BasicInfoUpdaters = {
  name: changeName,
  preferredUsername: changePreferredUsername,
  gender: changeGender,
  phoneNumber: changePhoneNumber,
  address: changeAddress,
  familyStructure: changeFamilyStructure,
  birthdate: changeBirthdate,
  profile: changeProfile,
};

const qualificationsAndWorkUpdaters: QualificationsAndWorkUpdaters = {
  qualificationCodeList: changeQualifications,
  employmentId: changeEmployment,
  affiliationIds: changeAffiliation,
  activityAreaIds: changeActivityArea,
  licenseIds: changeLicense,
  customLicense: changeCustomLicense,
  nurseLicenseNumber: changeNurseLicenseNumber,
};

const academicBackgroundUpdaters: AcademicBackgroundUpdaters = {
  school: changeSchool,
  scholarship: changeScholarship,
};

export const updaters = {
  basicInfo: basicInfoUpdaters,
  qualificationsAndWork: qualificationsAndWorkUpdaters,
  academicBackground: academicBackgroundUpdaters,
};
