import { v4 } from 'uuid';

import { appEnv as getAppEnv, oidcClientId } from '../constants/env';
import { MetaTag, metaTagList } from '../constants/metaTag';
import { appUrl } from '../constants/url';
import {
  createAuthorizationRequest,
  isAuthorizationRequest,
  isAuthorizationRequestQueryParams,
  validateRedirectUri,
} from '../domain/auth';
import { isSuccessResult } from '../domain/result';
import { confirmAuthenticatedInGetServerSideProps } from '../services/confirmAuthenticated';
import { LoginTemplate } from '../templates';

import type { AppEnv } from '@nurse-senka/nurse-senka-frontend-sdk';
import type { AuthorizationRequest } from '@nurse-senka/nurse-senka-web-ui';
import type { GetServerSideProps, NextPage } from 'next';

type Props = {
  appEnv: AppEnv;
  metaTag: MetaTag;
  queryParams?: { [key: string]: unknown };
  redirectUriAfterRegistration?: string;
  authorizationRequest?: AuthorizationRequest;
};

const LoginPage: NextPage<Props> = ({
  appEnv,
  metaTag,
  queryParams,
  redirectUriAfterRegistration,
  authorizationRequest,
}) => (
  <LoginTemplate
    appEnv={appEnv}
    metaTag={metaTag}
    queryParams={queryParams}
    redirectUriAfterRegistration={redirectUriAfterRegistration}
    initAuthorizationRequest={authorizationRequest}
  />
);

// eslint-disable-next-line require-await
export const getServerSideProps: GetServerSideProps = async (context) => {
  const props: Props = {
    appEnv: getAppEnv(),
    metaTag: metaTagList().login,
  };

  // eslint-disable-next-line no-magic-numbers
  if (Object.keys(context.query).length !== 0) {
    props.queryParams = context.query;
    if (Object.hasOwn(props.queryParams, 'redirect_uri')) {
      const redirectUri = props.queryParams.redirect_uri;
      if (validateRedirectUri(redirectUri) && typeof redirectUri === 'string') {
        props.redirectUriAfterRegistration = redirectUri;
      }
    }
  }

  if (isAuthorizationRequestQueryParams(context.query)) {
    const authorizationRequest = {
      clientId: Number(context.query.client_id),
      redirectUri: context.query.redirect_uri,
      state: context.query.state,
      nonce: context.query.nonce,
    };

    if (isAuthorizationRequest(authorizationRequest)) {
      props.authorizationRequest = authorizationRequest;
    }
  } else {
    // クエリパラメータがないと事実上機能しないので、必要なクエリパラメータが存在しない場合は補間する
    const defaultAuthorizationRequest = createAuthorizationRequest({
      clientId: oidcClientId(),
      redirectUri: appUrl.top,
      generateUniqueId: v4,
    });

    props.queryParams = {
      client_id: String(defaultAuthorizationRequest.clientId),
      redirect_uri: defaultAuthorizationRequest.redirectUri,
      state: defaultAuthorizationRequest.state,
      nonce: defaultAuthorizationRequest.nonce,
    };

    props.authorizationRequest = {
      clientId: Number(defaultAuthorizationRequest.clientId),
      redirectUri: defaultAuthorizationRequest.redirectUri,
      state: defaultAuthorizationRequest.state,
      nonce: defaultAuthorizationRequest.state,
    };
  }

  const confirmAuthenticatedResult =
    await confirmAuthenticatedInGetServerSideProps(
      { req: context.req, res: context.res },
      props.appEnv,
      appUrl.top,
    );
  if (isSuccessResult(confirmAuthenticatedResult)) {
    return {
      redirect: {
        permanent: false,
        destination: props.authorizationRequest?.redirectUri
          ? props.authorizationRequest?.redirectUri
          : appUrl.top,
      },
    };
  }

  return {
    props,
  };
};

export default LoginPage;
