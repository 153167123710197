/* eslint-disable max-lines, max-statements, max-lines-per-function */
import crypto from 'crypto';

import {
  validation,
  type HttpsUrl,
} from '@nurse-senka/nurse-senka-frontend-sdk';
import { AuthorizationRequest } from '@nurse-senka/nurse-senka-web-ui';
import psl from 'psl';
import { z } from 'zod';

import { appEnv } from '../constants/env';
import { apiPath, appBaseUrl } from '../constants/url';

import { AuthorizationCodeVerifierDoesNotMatchError } from './errors/AuthorizationCodeVerifierDoesNotMatchError';
import { AuthorizationStateDoesNotMatchError } from './errors/AuthorizationStateDoesNotMatchError';
import { OidcClientNotFoundError } from './errors/OidcClientNotFoundError';
import { Result, SuccessResult } from './result';

import type { Sub } from './user';

export type ClientId = number;

export type RedirectUri = HttpsUrl;

type CreateAuthorizationRequestParams = {
  clientId: ClientId;
  redirectUri: RedirectUri;
  generateUniqueId: () => string;
};

export type CreateAuthorizationRequest = (
  params: CreateAuthorizationRequestParams,
) => AuthorizationRequest;

export const createAuthorizationRequest: CreateAuthorizationRequest = ({
  clientId,
  redirectUri,
  generateUniqueId,
}) => ({
  clientId,
  redirectUri,
  state: generateUniqueId(),
  nonce: generateUniqueId(),
});

export type AccessTokenValue = string;

export type AccessToken = {
  accessToken: AccessTokenValue;
};

type GeneratePkceParamsRequest = {
  generateUniqueId: () => string;
  encoder: (base64Str: string) => string;
};

// PKCE(RFC 7636)対策用のバラメータを作成する
// https://www.authlete.com/documents/article/pkce/authorization_request
export const generatePkceParams = (request: GeneratePkceParamsRequest) => {
  const uniqueId = request.generateUniqueId();

  const sha512 = crypto.createHash('sha512');
  sha512.update(uniqueId);

  const codeVerifier = sha512.digest().toString('hex');

  const sha256 = crypto.createHash('sha256');
  sha256.update(codeVerifier);

  const codeChallenge = request.encoder(sha256.digest().toString('base64url'));

  return {
    codeVerifier,
    codeChallenge,
  };
};

type ClientSecret = string;

export type IssueAccessTokenInClientCredentials = (
  clientId: ClientId,
  clientSecret: ClientSecret,
  traceId?: string,
) => Promise<SuccessResult<AccessToken>>;

export const clientCredentialsWhenRequestScope = (): string[] => [
  'nsc_account_service_admin',
  'nsc_oauth_admin',
  'nsc_general_user',
];

export const isAllowedRedirectUri = (url: URL): boolean => {
  // localhostはドメイン名が取得出来ないので例外的に許可する
  if (url.hostname === 'localhost') {
    return true;
  }

  const allowedDomainNames = ['smsc-nsc.com', 'nurse-senka.jp'];

  const domain = psl.get(url.hostname);

  if (!domain) {
    return false;
  }

  const extracted = allowedDomainNames.find(
    (allowedDomainName) => allowedDomainName === domain,
  );

  if (extracted) {
    return true;
  }

  return false;
};

export const validateRedirectUri = (value: unknown) => {
  try {
    if (typeof value !== 'string') {
      return false;
    }

    const url = new URL(value);

    if (appEnv() === 'production') {
      if (url.protocol === 'http') {
        return false;
      }

      if (url.hostname === 'localhost') {
        return false;
      }
    }

    return isAllowedRedirectUri(url);
  } catch (error) {
    // new URL() 以外ではErrorはThrowされないので、握りつぶしても問題ないと思う
    return false;
  }
};

const authorizationRequestSchema = z.object({
  // eslint-disable-next-line no-magic-numbers
  clientId: z.number().min(1).max(99999999999999),
  redirectUri: z.string().refine((value) => validateRedirectUri(value)),
  state: z.string().uuid(),
  nonce: z.string().uuid(),
});

export const validateAuthorizationRequest = (request: unknown) =>
  validation(authorizationRequestSchema, request);

export const isAuthorizationRequest = (
  value: unknown,
): value is AuthorizationRequest =>
  validateAuthorizationRequest(value).isValidate;

export const createAuthorizationUrl = (queryParams: {
  [key: string]: unknown;
}): HttpsUrl => {
  const urlSearchParam = new URLSearchParams(
    queryParams as { [key: string]: string },
  ).toString();

  return `${appBaseUrl()}${apiPath.authorization}?${urlSearchParam}`;
};

type CodeChallengeMethod = 'S256' | 'plain';

export const isCodeChallengeMethod = (
  value: unknown,
): value is CodeChallengeMethod => {
  if (typeof value !== 'string') {
    return false;
  }

  return value === 'S256' || value === 'plain';
};

type AllowedResponseType = 'code';

export const isAllowedResponseType = (
  value: unknown,
): value is AllowedResponseType => {
  if (typeof value !== 'string') {
    return false;
  }

  return value === 'code';
};

export type ExternalAuthorizationRequest = AuthorizationRequest & {
  scope: string;
  responseType: AllowedResponseType;
  codeChallenge: string;
  codeChallengeMethod: CodeChallengeMethod;
};

const externalAuthorizationRequestSchema = z.object({
  // eslint-disable-next-line no-magic-numbers
  clientId: z.number().min(1).max(99999999999999),
  responseType: z.string().refine((value) => isAllowedResponseType(value)),
  redirectUri: z.string().url(),
  // eslint-disable-next-line no-magic-numbers
  scope: z.string().min(1),
  state: z.string(),
  nonce: z.string(),
  // eslint-disable-next-line no-magic-numbers
  codeChallenge: z.string().min(1),
  codeChallengeMethod: z
    .string()
    .refine((value) => isCodeChallengeMethod(value)),
});

export const validateExternalAuthorizationRequest = (request: unknown) =>
  validation(externalAuthorizationRequestSchema, request);

export const isExternalAuthorizationRequest = (
  value: unknown,
): value is ExternalAuthorizationRequest =>
  validateExternalAuthorizationRequest(value).isValidate;

type AuthorizationTicket = string;

type IssueAuthorizationTicketResponse = {
  ticket: AuthorizationTicket;
};

export type IssueAuthorizationTicket = (
  request: ExternalAuthorizationRequest,
) => Promise<IssueAuthorizationTicketResponse>;

type IssueAuthorizationCodeFromTicketRequest = {
  ticket: AuthorizationTicket;
  sub: Sub;
};

type IssueAuthorizationCodeFromTicketResponse = {
  redirectUriWithAuthorizationCode: string;
};

export type IssueAuthorizationCodeFromTicket = (
  request: IssueAuthorizationCodeFromTicketRequest,
) => Promise<IssueAuthorizationCodeFromTicketResponse>;

type FetchOidcClientRequest = {
  accessToken: AccessToken;
  clientId: ClientId;
};

type OidcClient = {
  clientId: ClientId;
  clientSecret: ClientSecret;
  clientName: string;
};

export type FetchOidcClient = (
  request: FetchOidcClientRequest,
) => Promise<Result<OidcClient, OidcClientNotFoundError>>;

export type IssueOidcTokensFromAuthorizationRequestDto =
  AuthorizationRequest & {
    accessToken: AccessToken;
    clientSecret: ClientSecret;
    sub: Sub;
    scopes: string[];
    codeChallenge: string;
    codeChallengeMethod: CodeChallengeMethod;
    codeVerifier: string;
  };

type IssueOidcTokensFromAuthorizationRequestResponse = {
  accessToken: AccessTokenValue;
  refreshToken: string;
  scope: string;
  idToken: string;
  expiresIn: number;
};

export type IssueOidcTokensFromAuthorizationRequest = (
  dto: IssueOidcTokensFromAuthorizationRequestDto,
) => Promise<
  Result<
    IssueOidcTokensFromAuthorizationRequestResponse,
    | AuthorizationStateDoesNotMatchError
    | AuthorizationCodeVerifierDoesNotMatchError
  >
>;

// ナース専科開発チーム以外が管理しているアプリケーションからも利用されているので、互換性を考えてスネークケースで型定義している
// https://smsc-co-ltd.atlassian.net/wiki/spaces/NSC/pages/26752094/Authorization+Code+Flow+Web
// https://smsc-co-ltd.atlassian.net/wiki/spaces/NSC/pages/610533377/NJB
type AuthorizationRequestQueryParams = {
  client_id?: string;
  redirect_uri?: string;
  state?: string;
  nonce?: string;
  response_type: string;
  scope: string;
  code_verifier?: string;
  code_challenge?: string;
  code_challenge_method?: string;
};

const authorizationRequestQueryParamsSchema = z.object({
  // eslint-disable-next-line no-magic-numbers
  client_id: z.string().min(1),
  // eslint-disable-next-line no-magic-numbers
  redirect_uri: z.string().min(1),
  state: z.string(),
  nonce: z.string(),
  // eslint-disable-next-line no-magic-numbers
  code_verifier: z.string().min(1).optional(),
  // eslint-disable-next-line no-magic-numbers
  code_challenge: z.string().min(1).optional(),
  code_challenge_method: z
    .string()
    .refine((value) => isCodeChallengeMethod(value))
    .optional(),
});

const validateQueryParams = (params: unknown) =>
  validation(authorizationRequestQueryParamsSchema, params);

export const isAuthorizationRequestQueryParams = (
  value: unknown,
): value is AuthorizationRequestQueryParams => {
  if (Object.prototype.toString.call(value) !== '[object Object]') {
    return false;
  }

  const validationResult = validateQueryParams(value);

  return validationResult.isValidate;
};
